<div class="row m-0 package-contents-container"  *ngIf="(((isActiveItemCount || isActiveItemDetails) && itemList.length) || isActiveReturnsInitiation)">
  <div class="col-12 package-contents-header">
    <div class="py-3 d-flex"
      [ngClass]="{'justify-content-flex-start':isActiveReturnsInitiation, 'justify-content-between':!isActiveReturnsInitiation}">
      <h2 class="title line-height-2 f-16px mb-0" *ngIf="(isActiveItemCount || isActiveItemDetails) && itemList.length">
        {{ 'GENERIC.PLATFORM.THIS_TEXT' | translate }} {{ 'GENERIC.PLATFORM.PACKAGE' | translate }}
      </h2>
      <span class="item-count line-height-2 px-3" *ngIf="isActiveItemCount && itemList.length">
        {{itemList.length}} {{ (itemList.length > 1 ? 'GENERIC.PLATFORM.ITEMS' : 'GENERIC.PLATFORM.ITEM') | translate | lowercase }}
      </span>
      <span class="return-btn-container line-height-2 px-3"  [ngClass]="{'mr-auto':!itemList.length || (!isActiveItemCount && !isActiveItemDetails)}" *ngIf="isActiveReturnsInitiation">
        <button (click)="openHostedReturnsUrl()" class="return-btn btn btn-primary primary-btn my-3">
          {{ 'GENERIC.PLATFORM.START_RETURN' | translate | uppercase }}
        </button>
      </span>
  </div>
  <div class="col-12" *ngIf="isActiveItemDetails && itemList.length">
    <div class="row m-0 package-contents-body">
      <ng-container *ngFor="let item of itemList; let index = index;">

        <!-- horizontal border b/w rows -->
        <hr class="col-12 px-0 my-0" *ngIf="index !== 0 && index % 2 === 0">

        <div class="col-12 col-md-6 px-0 position-relative" [ngClass]="{'pr-md-3': index % 2 === 0}">
          <div *ngIf="item.description" class="item-description-info position-absolute width-fit-content  ctooltip ctooltip-top" [attr.data-tooltip]="item.description">
            <span>&#9432;</span>
          </div>
          <div class=" d-flex py-3 pr-3 pr-lg-5">
            <div style="width: 100px; height: 100px;">
              <img style="max-width: 100%; max-height: 100%; text-align: center;" class="flex-grow-0 flex-shrink-0"
                src="{{item.image || '/assets/icons/package-item-placeholder.svg'}}" [alt]="item.title">
            </div>
            <div class="flex-fill pl-3 min-width-0">

              <div class="item-name f-18px width-fit-content" *ngIf="item.title">
                {{item.title}}
              </div>
              <div class="details f-14px " *ngIf="item.sku">
                <span class="font-weight-light">{{ 'GENERIC.PLATFORM.SKU_TEXT' | translate }}:</span> {{item.sku}}
              </div>

              <div class="d-flex justify-content-between  flex-wrap">
                <div class="details f-14px  width-fit-content" *ngIf="item.quantity">
                  <span class="font-weight-light">{{ 'GENERIC.PLATFORM.QUANTITY_TEXT' | translate }}:</span> {{item.quantity}}
                </div>
                <div class="details f-14px  width-fit-content"
                  *ngIf="item.sale_price !== undefined && item.price !== undefined">
                  <span class="font-weight-light">{{ 'GENERIC.PLATFORM.PRICE_TEXT' | translate }}:</span>
                  <span>&nbsp;${{item.sale_price}}</span>&nbsp;
                  <span style="text-decoration: line-through;" class="f-12px">${{item.price}}</span>
                </div>
                <div class="details f-14px  width-fit-content"
                  *ngIf="(item.sale_price === undefined && item.price !== undefined) || (item.sale_price !== undefined && item.price === undefined)">
                  <span class="font-weight-light">{{ 'GENERIC.PLATFORM.PRICE_TEXT' | translate }}:</span>${{item.price || item.sale_price}}
                </div>
              </div>
              <div class="d-flex justify-content-between flex-wrap">
                <div class="details f-14px  width-fit-content" *ngIf="item.weight">
                  <span class="font-weight-light">{{ 'GENERIC.PLATFORM.WEIGHT_TEXT' | translate }}:</span> {{item.weight}} lbs
                </div>
                <div class="details f-14px  width-fit-content" *ngIf="item.size">
                  <span class="font-weight-light">{{ 'GENERIC.PLATFORM.SIZE_TEXT' | translate }}:</span> {{item.size.length}} in x {{item.size.width}} in x {{item.size.height}} in
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>

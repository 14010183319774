<div #statusHistoryCnt class="status-history-cnt h-100" *ngIf="statusHistory.length"
  [ngClass]="{'closed': desktopMode && !isOpen}"
  [ngStyle]="{'width': componentWidth}">
  <div class="arrow-cnt d-flex cursor-pointer" (click)="isOpen = !isOpen" *ngIf="showArrows">
    <img *ngIf="isOpen" src="/assets/icons/leftward-arrow.svg" width="24" height="24">
    <img *ngIf="!isOpen" src="/assets/icons/rightward-arrow.svg" width="24" height="24">
  </div>
  <div class="ml-3 mt-3 f-10px">{{ 'HOSTED_TRACKING.STATUS_HISTORY.LAST_UPDATED_TODAY_AT' | translate }} {{latestTime}}</div>
  <div #statusHistoryDiv class="status-history">
  <div class="shipment-status d-flex align-items-stretch pl-3 pt-2"
      *ngIf="desktopMode">
      <div class="verticle-line mr-3 removeBottom" [ngClass]="{'removeTop': orderDelivered }" >
      </div>
      <div class="w-100">
      <div class="status-cnt my-2 py-2 px-3" *ngFor="let status of statusHistory; let i = index">
          <span class="disk"></span>
          <div class="d-flex justify-content-between">
            <div>
              <div class="f-14px" [style.font-weight]="i === 0 ? 'bold' : 'normal'">
                {{status.status | orderStatusMapping : orderType :
                'statusInfo'}}
              </div>
            <div class="f-10px" *ngIf="status.note">{{status.note}}</div>
            <div class="f-10px">{{status.formattedTime}}</div>
            </div>
            <div (click)="emitOpenModalEvent()" class="cursor-pointer"
              *ngIf="status.status === 'ORDER_DELIVERED' && attachments && attachments.length> 0">
              <ds-status-history-thumbnail [attachments]="attachments"></ds-status-history-thumbnail>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="shipment-status d-flex align-items-stretch pl-3 pt-2"
      *ngIf="!desktopMode">
      <div class="verticle-line mr-3"
        [ngClass]="{'removeBottom': showAllInMobileMode || statusHistory?.length === 1 , 'removeTop' : orderDelivered}">
      </div>
      <div class="w-100">
        <div class="status-cnt my-2 py-2 px-3" *ngFor="let status of mobileViewStatusList; let i = index">
          <span class="disk"></span>
          <div class="d-flex justify-content-between">
            <div>
            <div class="f-14px" [style.font-weight]="i === 0 ? 'bold' : 'normal'">{{status.status | orderStatusMapping : orderType :
              'statusInfo'}}</div>
            <div class="f-10px" *ngIf="status.note">{{status.note}}</div>
            <div class="f-10px">{{status.formattedTime}}</div>
            </div>
            <div (click)="emitOpenModalEvent()" class="cursor-pointer"
              *ngIf="status.status === 'ORDER_DELIVERED' && attachments && attachments.length > 0">
              <ds-status-history-thumbnail [attachments]="attachments"></ds-status-history-thumbnail>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button *ngIf="!desktopMode && (statusHistory.length > 1)" type="submit" class="btn btn-primary primary-btn my-3"
      (click)="toggleMobileStatusList()">
      {{ (showAllInMobileMode ? 'HOSTED_TRACKING.STATUS_HISTORY.HIDE' : 'HOSTED_TRACKING.STATUS_HISTORY.SHOW' ) | translate | uppercase }} {{ 'HOSTED_TRACKING.STATUS_HISTORY.PREVIOUS_ACTIVITIES' | translate | uppercase }}
    </button>
  </div>
</div>

<div class="no-status-history overflow-hidden p-3" *ngIf="!statusHistory || !statusHistory.length"
  [ngClass]="{'closed': desktopMode && !isOpen}" [ngStyle]="{'width': componentWidth, 'max-height' : '100%'}">
  <div class="arrow-cnt d-flex cursor-pointer" (click)="isOpen = !isOpen" *ngIf="showArrows">
    <img *ngIf="isOpen" src="/assets/icons/leftward-arrow.svg" width="24" height="24">
    <img *ngIf="!isOpen" src="/assets/icons/rightward-arrow.svg" width="24" height="24">
  </div>
  <img *ngIf="desktopMode" class="img-fluid w-100" src="../../../assets/icons/no-status-history.svg">
  <div *ngIf="!desktopMode">{{ 'HOSTED_TRACKING.STATUS_HISTORY.NO_HISTORY' | translate }}<br>{{ 'HOSTED_TRACKING.STATUS_HISTORY.CHECK_BACK' | translate }}</div>
</div>

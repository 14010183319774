<div class="row"> 
  <h1 *ngIf="showSiblingOrders" class="col orderIdTitle my-4">
    {{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} #{{order.groupId}} ( {{ 'GENERIC.PLATFORM.PACKAGE' | translate }} #{{order.orderExternalId}} )
  </h1>
  <h1 *ngIf="!showSiblingOrders" class="col orderIdTitle my-4">
    {{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} #{{order.orderExternalId}}
  </h1>
</div>

<!-- Compliance section start -->
  <div class="row">
    <div class="col">
      <app-compliance-info-box-section *ngIf="hostedTrackingConfig?.componentVisibility?.showComplianceInfo" [order]="order"
        [hostedTrackingConfig]="hostedTrackingConfig!"></app-compliance-info-box-section>
    </div>
  </div>
<!-- Compliance section end -->

<div class="custom-row pb-14px">
  <div class="custom-col-12 custom-col-md-6">
    <ds-order-status-card *ngIf="!(isSystemStatus || isNegativeStatus)" [order]="order"
      (detailsButtonClicked)="openDeliveryProofsModal.emit(true)">
    </ds-order-status-card>
    <ds-info-unavailable *ngIf="isSystemStatus || isNegativeStatus"></ds-info-unavailable>
  </div>
  <div class="custom-col-12 custom-col-md-6 d-none d-md-block">
    <ds-side-ad-banner [captureEvent]="'click-hosted-tracking-primary-banner'" [adconfig]="primaryAds"
      *ngIf="primaryAds"></ds-side-ad-banner>
  </div>
</div>

<div class="custom-row">
  <div class="custom-col-md-12 pb-14px">
    <div class="delivery-status-container">
      <!-- shows map, store, driver info -->
      <div class="delivery-status d-flex flex-column">
        <div class="d-flex flex-column flex-md-row flex-1-1-auto h-372px border-bottom-1px  position-relative">
          <div class="h-100 d-md-block d-none border-right-1px">
            <!-- desktop view -->
            <ds-status-history [timeZone]="order.timeZone" [_statusHistory]="order.statusHistory" [orderType]="order.type"
              [desktopMode]="true" [hostedTrackingConfig]="hostedTrackingConfig" [attachments]="order.attachments" (detailsButtonClicked)="openDeliveryProofsModal.emit(true)">
            </ds-status-history>
          </div>

          <!-- google map -->
          <div class="flex-1-1-auto">
            <ds-google-map [order]="order"></ds-google-map>
          </div>
        </div>

        <!-- mobile view -->
        <div class="d-md-none d-block border-bottom-1px">
          <ds-status-history [timeZone]="order.timeZone" [_statusHistory]="order.statusHistory" [orderType]="order.type"
            [hostedTrackingConfig]="hostedTrackingConfig" [attachments]="order.attachments" (detailsButtonClicked)="openDeliveryProofsModal.emit(true)"></ds-status-history>
        </div>

        <ds-store-driver-info [order]="order" [hostedTrackingConfig]="hostedTrackingConfig"></ds-store-driver-info>
      </div>
    </div>
  </div>
  <div class="custom-col-12 custom-col-md-6 d-md-none pb-14px">
    <ds-side-ad-banner [adconfig]="primaryAds" *ngIf="primaryAds"></ds-side-ad-banner>
  </div>
</div>

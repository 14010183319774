import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ds-service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss']
})
export class ServiceUnavailableComponent implements OnInit {
  @Input() headerTitle = "";

  header = this.translate.instant('GENERIC.COMMON_PAGES.SERVICE_UNAVAILABLE_HEADER');

  constructor(private translate: TranslateService) {
    document.title = this.translate.instant('GENERIC.COMMON_PAGES.SERVICE_UNAVAILABLE_TITLE');
    document.body.classList.add("background-gray");
  }

  ngOnInit(): void {
    this.header = this.headerTitle || this.header;

  }

}

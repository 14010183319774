<div class="feedback-container d-flex flex-column"
  [ngClass]="{'small-container': !shouldBeLarger, 'h-100': shouldBeLarger}">
  <div class="title-container">
    <h2 class="title mb-0">
      {{ 'GENERIC.COMMON_PAGES.FEEDBACK_TEXT' | translate }}
    </h2>
  </div>
  <form class="flex-grow-1 d-flex flex-column feedback-form" *ngIf="!isFeedbackShared" (submit)="onSubmit()">
    <div>
      <div class="align-items-center d-flex flex-column flex-lg-row justify-content-between p-3">
        <div class="update-text f-14px flex-shrink-1 mw-100">
          {{ 
            (_orderType === 'delivery' 
              ? 'GENERIC.COMMON_PAGES.FEEDBACK_RATE_EXPERIENCE_DELIVERY' 
              : orderType === 'shipping'
                ? 'GENERIC.COMMON_PAGES.FEEDBACK_RATE_EXPERIENCE_SHIPPING' 
                : 'GENERIC.COMMON_PAGES.FEEDBACK_RATE_EXPERIENCE_PICKUP'
            ) | translate
          }}
        </div>
        <div class="d-inline-flex flex-shrink-0 width-fit-content mt-3 mt-lg-0">
          <label class="ml-lg-4">
            <input type="radio" name="feedback" value="5" required class="feedback-radio"
              [(ngModel)]="formObject.rating">
            <img width="36" height="36" src="../../../assets/icons/smiley-1.svg" class="unselected">
            <img width="36" height="36" src="../../../assets/icons/smiley-1-s.svg" class="selected"
              [captureEvent]="'click-feedback-rating'">
          </label>
          <label class="ml-4 custom-ml-3-280px">
            <input type="radio" name="feedback" value="4" required class="feedback-radio"
              [(ngModel)]="formObject.rating">
            <img width="36" height="36" src="../../../assets/icons/smiley-2.svg" class="unselected">
            <img width="36" height="36" src="../../../assets/icons/smiley-2-s.svg" class="selected"
              [captureEvent]="'click-feedback-rating'">
          </label>
          <label class="ml-4 custom-ml-3-280px">
            <input type="radio" name="feedback" value="3" required class="feedback-radio"
              [(ngModel)]="formObject.rating">
            <img width="36" height="36" src="../../../assets/icons/smiley-3.svg" class="unselected">
            <img width="36" height="36" src="../../../assets/icons/smiley-3-s.svg" class="selected"
              [captureEvent]="'click-feedback-rating'">
          </label>
          <label class="ml-4 custom-ml-3-280px">
            <input type="radio" name="feedback" value="2" required class="feedback-radio"
              [(ngModel)]="formObject.rating">
            <img width="36" height="36" src="../../../assets/icons/smiley-4.svg" class="unselected">
            <img width="36" height="36" src="../../../assets/icons/smiley-4-s.svg" class="selected"
              [captureEvent]="'click-feedback-rating'">
          </label>
          <label class="ml-4 custom-ml-3-280px">
            <input type="radio" name="feedback" value="1" required class="feedback-radio"
              [(ngModel)]="formObject.rating">
            <img width="36" height="36" src="../../../assets/icons/smiley-5.svg" class="unselected">
            <img width="36" height="36" src="../../../assets/icons/smiley-5-s.svg" class="selected"
              [captureEvent]="'click-feedback-rating'">
          </label>
        </div>
      </div>
      <div class="mx-3">
        <textarea type="text" class="form-control" placeholder="{{ 'GENERIC.COMMON_PAGES.COMMENT' | translate }}" name="comment"
          [(ngModel)]="formObject.comment"
          [ngClass]="{'h-46px': !shouldBeLarger, 'h-146px': shouldBeLarger}">
          </textarea>
      </div>
    </div>
    <div class="flex-grow-1 d-flex flex-column justify-content-around">
      <button [captureEvent]="'submit-feedback'" type="submit" class="btn btn-primary primary-btn my-3" [disabled]="!formObject.rating">
				{{ 'GENERIC.COMMON_PAGES.SEND_FEEDBACK' | translate | uppercase }}
      </button>
    </div>
  </form>
  <div *ngIf="isFeedbackShared" class="feedback-shared d-flex flex-column justify-content-center"
    [ngClass]="{'mt-md-5': shouldBeLarger}">
    <div class="feedback-success mt-3"><img src="/assets/icons/feedback-shared.svg" width="20" height="20"
        alt="{{ 'GENERIC.COMMON_PAGES.AVATAR' | translate }}">
    </div>
    <h3 class="my-3">{{ 'GENERIC.COMMON_PAGES.THANK_YOU_TEXT' | translate }}!</h3>
    <p class="f-14px mb-3 px-3">
      {{ 
        (_orderType === 'delivery' 
          ? 'GENERIC.COMMON_PAGES.FEEDBACK_FOOTER_TEXT_DELIVERY' 
          : orderType === 'shipping'
            ? 'GENERIC.COMMON_PAGES.FEEDBACK_FOOTER_TEXT_SHIPPING' 
            : 'GENERIC.COMMON_PAGES.FEEDBACK_FOOTER_TEXT_PICKUP'
        ) | translate
      }}
    </p>
    <p class="cursor-pointer f-12px" [ngClass]="{'mb-md-5 pb-md-4 mt-md-3': shouldBeLarger}"
      (click)="isFeedbackShared = false" [captureEvent]="'edit-submitted-feedback'"><u>{{ 'GENERIC.COMMON_PAGES.EDIT_FEEDBACK' | translate }}</u></p>
  </div>
</div>

import { StatusHistory } from "src/app/ts-interfaces/order-interface";

export function addMinutesToDate(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes * 60000);
}

export function generateTimeFormatForStatusHistory(statusHistory: StatusHistory[], timeZone: string) {
  statusHistory.forEach(status => {
    const format = new Intl.DateTimeFormat('en-US', {
      timeZone,
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    });

    status.formattedTime = format.format(new Date(status.updatedAtEpoch));
  });
}

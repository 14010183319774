import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderTypeController } from "@deliverysolutions/order-status-mapping";
import { HostedTrackingConfig, AdConfig } from "../../ts-interfaces/hosted-tracking-config";
import { OrderInterface, StatusHistory } from "../../ts-interfaces/order-interface";

@Component({
  selector: 'ds-pickup-order-details',
  templateUrl: './pickup-order-details.component.html',
  styleUrls: ['./pickup-order-details.component.scss']
})
export class PickupOrderDetailsComponent implements OnInit {
  @Input() order!: OrderInterface;
  @Input() hostedTrackingConfig!: HostedTrackingConfig;
  @Input() isSystemStatus!: boolean;
  @Input() isTerminalStatus!: boolean;
  @Input() showSiblingOrders!: boolean;
  @Output() openDeliveryProofsModal: EventEmitter<boolean> = new EventEmitter();

  public primaryAds: AdConfig | undefined;
  statusHistory: StatusHistory[] = [];
  public orderTypeController = OrderTypeController;

  constructor() { }

  ngOnInit(): void {
    // primary ad is mandatory
    this.primaryAds = this.hostedTrackingConfig.components.ads.find(ads => {
      return ads.active && ads.type === "primary";
    });
  }
}

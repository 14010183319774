<div class="row">
  <h1 *ngIf="showSiblingOrders" class="col orderIdTitle my-4">
    {{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} #{{order.groupId}} ( {{ 'GENERIC.PLATFORM.PACKAGE' | translate }} #{{order.orderExternalId}} )
  </h1>
  <h1 *ngIf="!showSiblingOrders" class="col orderIdTitle my-4">
    {{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} #{{order.orderExternalId}}
  </h1>
</div>


<!-- Compliance section start -->
<div class="row">
  <div class="col">
    <app-compliance-info-box-section *ngIf="hostedTrackingConfig?.componentVisibility?.showComplianceInfo" [order]="order"
      [hostedTrackingConfig]="hostedTrackingConfig!"></app-compliance-info-box-section>
  </div>
</div>
<!-- Compliance section end -->

<div class="custom-row">
  <div class="custom-col-12 custom-col-md-6 pb-14px">
    <ds-order-status-card *ngIf="!isSystemStatus" [order]="order"
      (detailsButtonClicked)="openDeliveryProofsModal.emit(true)"></ds-order-status-card>
    <ds-info-unavailable *ngIf="isSystemStatus"></ds-info-unavailable>
  </div>

  <!-- visible only on desktop view -->
  <div class="custom-col-12 custom-col-md-6 d-none d-md-block">
    <ds-side-ad-banner [adconfig]="primaryAds" *ngIf="primaryAds"></ds-side-ad-banner>
  </div>
</div>

<div class="custom-row">
  <div class="custom-col-md-12 pb-14px">

    <div class="shipment-status-container">
      <div class="hst-delivery d-flex flex-column h-100">
        <div class="d-flex flex-column flex-md-row flex-1-1-auto h-372px border-bottom-1px  position-relative">
          <div class="h-100 d-md-block d-none border-right-1px">
            <!-- desktop view -->
            <ds-status-history [timeZone]="order.timeZone" [_statusHistory]="order.statusHistory" [orderType]="order.type"
              [desktopMode]="true" [attachments]="order.attachments" (detailsButtonClicked)="openDeliveryProofsModal.emit(true)">
            </ds-status-history>
          </div>
        
          <!-- google map -->
          <div class="flex-1-1-auto">
            <ds-google-map [order]="order"></ds-google-map>
          </div>
        </div>
        <!-- mobile view -->
        <div class="d-md-none d-block border-bottom-1px">
          <ds-status-history [timeZone]="order.timeZone" [_statusHistory]="order.statusHistory" [orderType]="order.type"
            [attachments]="order.attachments" (detailsButtonClicked)="openDeliveryProofsModal.emit(true)"></ds-status-history>
        </div>
        <ds-store-driver-info [order]="order" [hostedTrackingConfig]="hostedTrackingConfig"></ds-store-driver-info>
      </div>
    </div>
  </div>
  
  <!-- visible only on mobile and tablet view view -->
  <div class="custom-col-12 custom-col-md-6 pb-14px d-md-none">
    <ds-side-ad-banner [adconfig]="primaryAds" *ngIf="primaryAds"></ds-side-ad-banner>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-info-unavailable',
  templateUrl: './order-info-unavailable.component.html',
  styleUrls: ['./order-info-unavailable.component.scss']
})
export class OrderInfoUnavailableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { AdConfig } from "../../ts-interfaces/hosted-tracking-config";

@Component({
  selector: 'ds-side-ad-banner',
  templateUrl: './side-ad-banner.component.html',
  styleUrls: ['./side-ad-banner.component.scss']
})
export class SideAdBannerComponent implements OnInit {
  @Input() adconfig!: AdConfig;
  @Input() largerHeight = false;

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { OrderInterface } from 'src/app/ts-interfaces/order-interface';

@Component({
  selector: 'ds-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements OnInit {
  @Input() order: OrderInterface | undefined;
  @Input() tenantId?: string;
  dsLink = 'https://www.deliverysolutions.co/';

  constructor() { }

  ngOnInit(): void {
    let tenantId;
    if (!this.tenantId) {
      const urlPath = window.location.pathname.substring(1);
      tenantId = urlPath.split("/")[0];
    } else {
      tenantId = this.tenantId;
    }

    if (this.order && tenantId) {
      this.dsLink = 'https://www.deliverysolutions.co/?utm_source='
        + tenantId + '&utm_medium=hosted-tracking&utm_content='
        + this.order.carrier?.providerInfo.name || this.order.provider;
    }
  }

}

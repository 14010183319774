import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from "@angular/router";
import { HostedFeedbackConfig, AdConfig, Recommendations, FeedbackVisibility } from "../../ts-interfaces/hosted-tracking-config";
import { OrderInterface } from "../../ts-interfaces/order-interface";
import { DARK, DARK_CONSTRAST_COLOR, LIGHT, NOT_FOUND, TRACK_CONSTRAST_COLOR } from "../../shared/constants/generic-constants";
import { contrastColorOf } from "../../shared/functions/generate-contrast-color";
import { TrackerService } from "../../services/tracker.service";
import { OrderService } from "../../services/order.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OrderTypeController } from '@deliverysolutions/order-status-mapping';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { ClevertapAnalyticsService } from 'src/app/services/clevertap-analytics.service';
import { NullEmptyChecker } from "@deliverysolutions/utils";
import { HostedTrackingConfigService } from 'src/app/services/hosted-tracking-config.service';
import { Userpilot } from "userpilot";
import { MD5 } from "crypto-js";


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ds-feedback-page',
  templateUrl: './feedback-page.component.html',
  styleUrls: ['./feedback-page.component.scss']
})
export class FeedbackPageComponent implements OnInit {
  public hostedFeedbackConfig!: HostedFeedbackConfig;
  public secondaryAdsOne!: AdConfig | undefined;
  public secondaryAdsTwo!: AdConfig | undefined;
  public recommendations: Recommendations[] = [];
  public primaryAds: AdConfig | undefined;
  public order: OrderInterface | undefined;
  public fetchedOrder: OrderInterface | undefined;
  public isNavBackgroundWhite = false; // any default works

  private destroy$ = new Subject();
  public liveStreaming = false;
  public isClientToken = false;
  adsToShow!: AdConfig | undefined;
  isFeedbackBoxVisible: boolean = true; // any default works
  public feedbackNumber: number = 0;
  public autoSaveFeedback = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private trackerService: TrackerService,
    private orderService: OrderService,
    private gaAnalyticsService: GoogleAnalyticsService,
    private ctAnalyticsService: ClevertapAnalyticsService
  ) { }

  ngOnInit() {
    this.gaAnalyticsService.setupDSGoogleAnalytics();

    this.activatedRoute.queryParams.subscribe(async (params) => {
      this.feedbackNumber = params['feedbackNumber'];
      this.autoSaveFeedback = this.feedbackNumber ? true : false;
    });

    this.activatedRoute.data.subscribe(async (data) => {
      if (
        data === undefined // this happens when there is no token in query params or brand id in url or in http error
      ) {
        this.router.navigate(['/service-unavailable'], { skipLocationChange: true });
        return;
      }
      this.hostedFeedbackConfig = data.hostedFeedbackConfig;

      // data.orderDetails is undefiend when there is not order available
      this.fetchedOrder = data.orderDetails && data.orderDetails.data;

      let feedback = {
        rating: this.feedbackNumber,
        source: '',
        comment: ''
      };

      //if feedback passed through url exist, then prefil rating got from url
      if (this.feedbackNumber) {

        if (this.fetchedOrder) {
          this.fetchedOrder.isFeedbackShared = false;

          if (this.fetchedOrder.feedback) {
            this.fetchedOrder.feedback.rating = this.feedbackNumber;
          }
          else {
            this.fetchedOrder.feedback = feedback;
          }
        }
      }


      if (this.fetchedOrder && this.fetchedOrder.redirectUrl) {
        window.open(this.fetchedOrder.redirectUrl, '_self');
      }

      if (this.hostedFeedbackConfig) {
        this.setupTheme(this.hostedFeedbackConfig);
        this.setUpAdsConfig(this.hostedFeedbackConfig);
        this.setFontVariables(this.hostedFeedbackConfig);
        this.setupRecommendations(this.hostedFeedbackConfig);
        this.setPageTitleAndFavicon();
      }

      this.setupStreamingService();

      this.setupGoogleAnalytics();
      this.setupCleverTapAnalytics();
      this.setupUserPilotIdentifier();
    });
  }
  setupUserPilotIdentifier() {
    const tenantId = this.hostedFeedbackConfig?.tenantId;
    const brandName = this.hostedFeedbackConfig.brandName;
    const userId = MD5(this.fetchedOrder?.deliveryContact.phone!).toString();
    Userpilot.identify(userId, {
      tenantId,
      company: {
        id: tenantId,
        module: `hosted-feedback`,
        brand: brandName,
      },
    });
  }

  private setupStreamingService() {
    let { token, trackingNumber, provider } = this.activatedRoute.snapshot.queryParams;
    let tenantId = this.hostedFeedbackConfig?.tenantId;
    if (!token) {
      token = this.activatedRoute.snapshot.queryParams.ct;
      this.isClientToken = token ? true : false;
    }

    // sometimes order details aren't present,
    // in that case do nothing...
    // token is required for streaming service.
    if (!this.fetchedOrder || (!token && !(trackingNumber && provider))) {
      return;
    }
    const streamSource = this.trackerService.subscribeTrackerInfo(
      this.fetchedOrder._id,
      token,
      this.isClientToken,
      tenantId,
      trackingNumber,
      provider,
      (order) => {
        // on first call streaming returns empty object {}
        if (!order || !NullEmptyChecker.isNonEmptyArray(Object.keys(order))) {
          return;
        }

        this.order = Object.assign({}, this.order, order);
      },
      (error) => { console.log('streaming service error: ', error) }
    );
  }

  private setupRecommendations(hostedFeedbackConfig: HostedFeedbackConfig) {
    const recommendations = hostedFeedbackConfig.components.recommendations;
    const orderId = this.order ? this.order.orderExternalId : '';
    if (recommendations) {
      if (recommendations.url) {
        this.orderService.fetchExternalRecommendations(recommendations.url, orderId, 'hosted-feedback')
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            if (Array.isArray(result) && result.length) {
              this.recommendations = result;
            }

          });
      } else {
        this.recommendations = hostedFeedbackConfig.components.recommendations.jsonFileId;
      }
    }
  }

  private setUpAdsConfig(hostedFeedbackConfig: HostedFeedbackConfig) {
    this.secondaryAdsOne = hostedFeedbackConfig.components.ads.find(ads => {
      return ads.active && ads.type === "secondaryOne" && ads.url && ads.clickthroughLink;
    });

    this.secondaryAdsTwo = hostedFeedbackConfig.components.ads.find(ads => {
      return ads.active && ads.type === "secondaryTwo" && ads.url && ads.clickthroughLink;
    });

    this.primaryAds = hostedFeedbackConfig.components.ads.find(ads => {
      return ads.active && ads.type === "primary" && ads.url && ads.clickthroughLink;
    });

    this.adsToShow = this.primaryAds

  }

  // setup page theme. these css variables will be used cross page for proper brand experience
  private setupTheme(hostedFeedbackConfig: HostedFeedbackConfig) {
    if (hostedFeedbackConfig.branding?.color?.primary) {
      const contrastColor = contrastColorOf(hostedFeedbackConfig.branding.color.primary);
      if (contrastColor === DARK_CONSTRAST_COLOR) {
        document.body.setAttribute(TRACK_CONSTRAST_COLOR, DARK);
      } else {
        document.body.setAttribute(TRACK_CONSTRAST_COLOR, LIGHT);
      }

      // primary color for site
      document.documentElement.style
        .setProperty('--primary', hostedFeedbackConfig.branding.color.primary);
      // when you use primary as background use this as font color
      document.documentElement.style
        .setProperty('--primary-contrast', contrastColor); // contrasting black or white
    } else {
      // default primary color for site, when no primary color is given
      document.documentElement.style
        .setProperty('--primary', '#122344');
      // when you use primary as background use this as font color
      document.documentElement.style
        .setProperty('--primary-contrast', '#cccccc'); // contrasting black or white
    }

    // for site nav
    if (hostedFeedbackConfig.components.header?.fontColor) {
      document.documentElement.style
        .setProperty('--nav-primary', hostedFeedbackConfig.components.header.fontColor);
    }
    if (hostedFeedbackConfig.components.header?.fontSize) {
      document.documentElement.style
        .setProperty('--nav-size', hostedFeedbackConfig.components.header.fontSize + 'px');
    }
    if (hostedFeedbackConfig.components.header?.backgroundColor) {
      document.documentElement.style
        .setProperty('--nav-bg', hostedFeedbackConfig.components.header.backgroundColor);

      const navBg = hostedFeedbackConfig.components.header.backgroundColor;

      this.isNavBackgroundWhite = navBg === '#fff' || navBg === '#ffffff' || navBg === 'white';


      const navBgContrast = contrastColorOf(hostedFeedbackConfig.components.header.backgroundColor);

      document.documentElement.style
        .setProperty('--nav-bg-contrast', navBgContrast);
    }
  }

  private setPageTitleAndFavicon() {
    if (
      this.hostedFeedbackConfig
      && this.hostedFeedbackConfig.branding.favicon?.active
      && this.hostedFeedbackConfig.branding.favicon.pageTitle
    ) {
      document.title = this.hostedFeedbackConfig.branding.favicon.pageTitle;
    } else if (this.order) {
      document.title = `Order #${this.order.orderExternalId} | ${this.order.brandName}`;
    } else if (this.hostedFeedbackConfig) {
      document.title = this.hostedFeedbackConfig.brandName;
    } else {
      document.title = 'Page not found';
    }

    if (
      this.hostedFeedbackConfig
      && this.hostedFeedbackConfig.branding.favicon?.active
      && this.hostedFeedbackConfig.branding.favicon.url
    ) {
      const link = document.createElement('link');
      link.rel = 'icon';
      link.type = 'image/png';
      link.href = this.hostedFeedbackConfig.branding.favicon.url;
      document.head.appendChild(link);
    }
  }

  // use fonts provider by brand for page
  private setFontVariables(hostedFeedbackConfig: HostedFeedbackConfig) {
    let content = "", heading = "", nav = "";

    if (hostedFeedbackConfig.branding.fonts?.content) {
      const contentFontType = hostedFeedbackConfig.branding.fonts.content.split('.').pop();

      content = `@font-face {
        font-family: 'content_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${hostedFeedbackConfig.branding.fonts.content}) format('${contentFontType}');
      }`;
    }

    if (hostedFeedbackConfig.branding.fonts?.heading) {
      const headingFontType = hostedFeedbackConfig.branding.fonts.heading.split('.').pop();

      heading = `@font-face {
        font-family: 'heading_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${hostedFeedbackConfig.branding.fonts.heading}) format('${headingFontType}');
      }`;
    }

    if (hostedFeedbackConfig.branding.fonts?.navigation) {
      const navFontType = hostedFeedbackConfig.branding.fonts.navigation.split('.').pop();

      nav = `@font-face {
        font-family: 'nav_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${hostedFeedbackConfig.branding.fonts.navigation}) format('${navFontType}');
      }`;
    }

    const style = document.createElement('style');
    style.innerHTML = `
      ${content}
      ${heading}
      ${nav}
    `;

    document.head.appendChild(style);
  }

  setupGoogleAnalytics() {
    this.hostedFeedbackConfig && this.gaAnalyticsService.setupGoogleAnalytics(this.hostedFeedbackConfig);
  }

  setupCleverTapAnalytics() {
    this.hostedFeedbackConfig && this.ctAnalyticsService.setupClevertapAnalytics(this.hostedFeedbackConfig);
  }

  // ngOnDestroy() {
  //   this.streamSources.forEach(subs => subs.close());
  // }

}

<!-- when logo is aligned on the left/right of nav items -->
<nav class="navbar navbar-expand-md pt-2 pt-md-3 navbar-light "
  *ngIf="header?.align === 'left' || header?.align === 'right'"
  [ngClass]='{"flex-row-reverse": header?.align === "right", "bottom-border" : isBackgroundWhite}'>
  <div class="d-flex align-items-center position-relative justify-content-center logo-wrapper">
    <!-- menu button for mobile-->
    <button class="navbar-toggler position-absolute l-0"
      [ngClass]="(!header?.navigationMenu?.active) ? 'd-none' : 'null'" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation" (click)="openNav()">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- business logo -->
    <a class="logo-link" rel="noopener  noreferrer" target="_blank" [ngClass]='{"mr-md-3": header.align === "left"}' [href]="headerLogo?.externalLink">
      <img [src]="headerLogo?.url" height="70" alt="{{ 'GENERIC.COMMON_PAGES.BUSINESS_LOGO_ALTERNATE_TEXT' | translate }}">
    </a>

  </div>

  <div class="collapse navbar-collapse nav-items px-3 px-md-0" id="navbarSupportedContent"
    *ngIf="header?.navigationMenu?.active">
    <ul
      class="navbar-nav flex-nowrap flex-md-wrap mx-md-auto flex-md-row flex-column justify-content-between justify-content-md-start">
      <li class="nav-item mr-3" *ngFor="let item of header?.navigationMenu?.menuItems">
        <a class="nav-link" target="_blank" rel="noopener noreferrer" [href]="item.url">{{item.displayText}}</a>
      </li>
    </ul>
  </div>


</nav>

<!-- when logo is aligned on the center of header -->
<nav class="navbar navbar-expand-md navbar-light flex-wrap pt-2 pt-md-3 mt-3 px-0 w-100"
  *ngIf="header?.align === 'center'" [ngClass]='{"bottom-border" : isBackgroundWhite}'>
  <!-- business logo -->
  <div class="d-flex align-items-center margin-auto">
    <button class="navbar-toggler position-absolute l-0"
      [ngClass]="(!header?.navigationMenu?.active) ? 'd-none' : 'null'" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation" (click)="openNav()">
      <span class="navbar-toggler-icon"></span>
    </button>


    <a target="_blank" rel="noopener noreferrer" class="text-center w-100 logo-link" [href]="headerLogo?.externalLink">
      <img [src]="headerLogo?.url" height="70" alt="{{ 'GENERIC.COMMON_PAGES.BUSINESS_LOGO_ALTERNATE_TEXT' | translate }}">
    </a>
  </div>


  <div class="collapse navbar-collapse w-100 nav-items px-0" id="navbarSupportedContent"
    *ngIf="header?.navigationMenu?.active">
    <ul class="navbar-nav flex-column flex-md-row mx-md-auto justify-content-between justify-content-md-start flex-wrap">
      <li class="nav-item mr-3" *ngFor="let item of header?.navigationMenu?.menuItems">
        <a class="nav-link" target="_blank" rel="noopener noreferrer" [href]="item.url">{{item.displayText}}</a>
      </li>
    </ul>
  </div>
</nav>

<!-- mobile navbar -->
<div #sidenav id="side-nav" class="mobile-nav sidenav d-md-none">
  <ul class="px-0 mb-0 nav-head">
    <li class="navbar-nav flex-row">
      <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">
        <img src="/assets/icons/sidenav-arrow.svg">
      </a>
      <a target="_blank" rel="noopener noreferrer" class="text-center w-100 logo-link" [href]="headerLogo?.externalLink">
        <img [src]="headerLogo?.url" height="70" alt="{{ 'GENERIC.COMMON_PAGES.BUSINESS_LOGO_ALTERNATE_TEXT' | translate }}">
      </a>
    </li>
  </ul>
  <ul class="navbar-nav menu-item-list">
    <li class="nav-item  py-2" *ngFor="let item of header?.navigationMenu?.menuItems">
      <a class="nav-link" target="_blank" rel="noopener noreferrer" [href]="item.url">{{item.displayText}}</a>
    </li>
  </ul>
</div>

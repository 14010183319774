import { Injectable } from '@angular/core';
import {
  Resolve,
} from '@angular/router';
import { Observable } from 'rxjs';
import { HostedTrackingConfigService } from '../hosted-tracking-config.service';
import { HostedTrackingConfig } from 'src/app/ts-interfaces/hosted-tracking-config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HostedTrackingConfingResolver implements Resolve<any> {

  constructor(
    private hostedTrackingConfigService: HostedTrackingConfigService
  ) { }

  resolve(): Observable<HostedTrackingConfig | undefined> {
    // when user lands on custom domain fetch config based on custom domain.
    if (environment.env !== "local" && !window.location.host.includes('localhost') && !window.location.host.endsWith('deliverysolutions.co')) {
      return this.hostedTrackingConfigService.fetchHostedTrackingConfig(undefined, window.location.host);
    }

    return this.hostedTrackingConfigService.fetchHostedTrackingConfig();
  }
}

  <div class="row">
    <div class="col pb-2 pt-3">
      <h2 class="text-center"><span>{{ 'GENERIC.COMMON_PAGES.HEADER' | translate }}</span></h2>
    </div>
  </div>
  <div class="row mb-2 position-relative">
    <div class="col swiper-container">
      <swiper [config]="config">
        <div class="swiper-wrapper">
          <a [href]="recommendation.productUrl" target="_blank" rel="noopener noreferrer" class="recommendation-link text-decoration-none swiper-slide d-block" *ngFor="let recommendation of recommendations">
            <div class="product-wrapper text-center position-relative">
              <div class="img-container">
                  <img [src]="recommendation.imageUrl" />
              </div>
            </div>
            <div>
              <span class="product-name">
              {{recommendation.productName}}
            </span>
            </div>
          </a>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </swiper>
    </div>
    <!-- Add Arrows -->
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div>

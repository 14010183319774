import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { StringValidator } from '@deliverysolutions/utils';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationVisibility } from 'src/app/ts-interfaces/hosted-tracking-config';
import { SubcriptionService } from '../../services/subscription.service';

@Component({
  selector: 'ds-subscribe-to-updates',
  templateUrl: './subscribe-to-updates.component.html',
  styleUrls: ['./subscribe-to-updates.component.scss']
})
export class SubscribeToUpdatesComponent implements OnInit, OnChanges {
  @Input() orderIdList!: string[];
  @Input() orderType!: string;
  @Input() subscribeToUpdateOptions!: CommunicationVisibility;
  @Input() shouldBeLarger = false;

  orderTypeConverted!: string;

  selectedTab: string = 'mobile';

  updateText: string = '';
  unsubscribeText: string = '';
  subscriptionForm!: UntypedFormGroup;
  subscriptionFailed!: boolean;
  submitButtonDisabled!: boolean;
  showSubscriptionSuccessMessage!: boolean;
  showSubscriptionForm!: boolean;
  showUnsubscriptionForm!: boolean;
  showUnsubscribeButton!: boolean;
  showSubscribeButton!: boolean;
  subscriptionFailedMessage!: string;
  subscriptionSuccessMessage!: string;
  unSubscriptionSuccessMessage!: string;

  constructor(
    private subcriptionService: SubcriptionService,
    private _fb: UntypedFormBuilder,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.orderTypeConverted = this.orderType.replace(/-/g, '_');
    this.subscriptionForm = this._fb.group({
      email: ['', { validators: [this.emailValidator], updateOn: "blur" }],
      mobile: ['', { validators: [this.phoneNumberValidator], updateOn: "blur" }]
    })
    this.setDefaultState();
    this.updateText = this.translate.instant(`HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.${this.orderTypeConverted.toUpperCase()}_TEXT_UPDATE${this.orderIdList.length > 1 ? 'S' : ''}`);
    this.subscriptionFailed = false;
    this.checkNumberofActiveSubscriptionWidgets(this.orderType);
  }

  ngOnChanges(): void {
    this.setDefaultState();
  }

  setDefaultState() {
    this.unsubscribeText = "";
    this.subscriptionFailed = false;
    this.submitButtonDisabled = true;
    this.showSubscriptionSuccessMessage = false;
    this.showSubscriptionForm = true;
    this.showUnsubscriptionForm = false;
    this.showUnsubscribeButton = false;
    this.showSubscribeButton = false;
    this.subscriptionFailedMessage = "";
    this.subscriptionSuccessMessage = "";
    this.unSubscriptionSuccessMessage = "";
    this.subscriptionForm.reset();
  }

  changeTab(value: string) {
    this.selectedTab = value;
    value = value === 'mobile' ? 'text' : value;
    this.updateText = this.translate.instant(`HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.${this.orderTypeConverted.toUpperCase()}_${value.toUpperCase()}_UPDATE${this.orderIdList.length > 1 ? 'S' : ''}`);
    this.subscriptionFailed = false;
    this.showSubscriptionSuccessMessage = false;
    this.subscriptionFailedMessage = '';
    this.showUnsubscribeButton = false;
    this.showSubscriptionForm = true;
    this.showUnsubscriptionForm = false;
    this.subscriptionForm.reset();
  }

  submitForm(formDetails: any) {
    if (this.orderIdList.length > 1) {
      this.subscribeBulkOrders(formDetails, this.orderIdList);
    } else {
      this.subscribeToSingleOrder(formDetails, this.orderIdList[0], this.orderTypeConverted);
    }
  }

  phoneNumberValidator(control: any) {
    let phoneNumberValue;
    if (control.value && control.value !== '') {
      phoneNumberValue = control.value.split(" ").join("");
      if (phoneNumberValue.match(/^\+?([0-9]{1,3})?[0-9]{10}$/)) {
        return null;
      } else {
        return { 'invalidNumber': true };
      }
    }
    else {
      return null;
    }
  }

  emailValidator(control: any) {
    let emailValue;
    if (control.value && control.value !== '') {
      emailValue = control.value;
      if (StringValidator.isValidEmail(emailValue)) {
        return null;
      } else {
        return { 'invalidEmail': true };
      }
    }
    else {
      return null;
    }
  }

  checkButtonStatus() {
    if (this.selectedTab === 'mobile' && (this.subscriptionForm?.get('mobile')?.value === '' || !this.subscriptionForm?.get('mobile')?.value) ||
      this.selectedTab === 'email' && (this.subscriptionForm?.get('email')?.value === "" || !this.subscriptionForm?.get('email')?.value)
      || this.subscriptionForm?.get(this.selectedTab)?.status === 'INVALID') {
      return true;
    }
    else {
      return false;
    }
  }

  onUnsubscribe(formDetails: any) {
    if (this.orderIdList.length > 1) {
      this.unsubscribeBulkOrders(formDetails);
    } else {
      this.unsubscribeToSingleOrder(formDetails, this.orderIdList[0]);
    }
  }

  onUnsubscribeClicked() {
    if (this.selectedTab === 'mobile') {
      this.unsubscribeText = this.translate.instant('HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.ENTER_MOBILE_TO_UNSUBSCRIBE');
    } else if (this.selectedTab === 'email') {
      this.unsubscribeText = this.translate.instant('HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.ENTER_EMAIL_TO_UNSUBSCRIBE');
    }

    this.subscriptionForm.reset();
    this.subscriptionFailed = false;
    this.subscriptionFailedMessage = '';
    this.showSubscriptionSuccessMessage = false;
    this.showUnsubscribeButton = false;
    this.showSubscriptionForm = true;
    this.showUnsubscriptionForm = true;
  }

  onSubscribeForAnotherClick() {
    this.subscriptionForm.reset();
    this.subscriptionFailed = false;
    this.subscriptionFailedMessage = '';
    this.showSubscriptionSuccessMessage = false;
    this.showUnsubscribeButton = false;
    this.showSubscriptionForm = true;
    this.showUnsubscriptionForm = false;
  }

  checkNumberofActiveSubscriptionWidgets(orderType: string) {
    if (this.subscribeToUpdateOptions) {
      if (this.subscribeToUpdateOptions.active) {
        let widgetCount = 0;
        let widgetLabel = '';
        Object.entries(this.subscribeToUpdateOptions.channels).forEach(([key, value]) => {
          if (value === true) {
            widgetCount++;
            widgetLabel = (key === 'isActiveSms') ? 'mobile' : 'email';
          }
        });

        if (widgetCount === 1) {
          this.selectedTab = widgetLabel;
          let labelText = widgetLabel === 'mobile' ? 'text' : widgetLabel;
          this.updateText = this.translate.instant(`HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.${this.orderTypeConverted.toUpperCase()}_${labelText.toUpperCase()}_UPDATE${this.orderIdList.length > 1 ? 'S' : ''}`);
        }
      }
    }
  }

  subscribeToSingleOrder(formDetails: any, orderId: string, orderType: string) {
    let data = this.selectedTab === 'email' ? formDetails.controls.email.value : formDetails.controls.mobile.value;
    let body = {
      data: data,
      type: this.selectedTab === 'mobile' ? 'sms' : 'email'
    };

    this.subcriptionService.subscribeToUpdates(body, orderId).subscribe((data: any) => {
      if (data?.message === "already subscribed") {
        this.subscriptionSuccessMessage = this.selectedTab === 'mobile'
          ? this.translate.instant(`HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.${orderType.toUpperCase()}_MOBILE_ALREADY_SUBSCRIBED`)
          : this.translate.instant(`HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.${orderType.toUpperCase()}_EMAIL_ALREADY_SUBSCRIBED`);
      }
      else {
        let tabValue = this.selectedTab === 'mobile' ? 'text' : this.selectedTab;
        this.subscriptionSuccessMessage = this.translate.instant(`HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.${orderType.toUpperCase()}_${tabValue.toUpperCase()}_SIGNED_UP`);
      }
      this.showSubscriptionSuccessMessage = true;
      this.showUnsubscribeButton = true;
      this.showSubscribeButton = false;
      this.showSubscriptionForm = false;
      this.subscriptionFailedMessage = '';
      this.subscriptionFailed = false;
    }, (err: any) => {
      let message = '';

      if (err.status > 499 || err.status < 400) {
        message = this.translate.instant('HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.UNKNOWN_ERROR');
      } else {
        message = err.error.message;
      }

      this.subscriptionFailed = true;
      this.subscriptionFailedMessage = message;
      this.showSubscriptionSuccessMessage = false;
      this.showSubscribeButton = false;
    });
  }

  unsubscribeToSingleOrder(formDetails: any, orderId: string) {
    let data = this.selectedTab === 'email' ? formDetails.controls.email.value : formDetails.controls.mobile.value;
    let body = {
      data: data,
      type: this.selectedTab === 'mobile' ? 'sms' : 'email'
    };
    this.subcriptionService.unsubscribeToUpdates(body, orderId).subscribe((data: any) => {
      if (this.selectedTab === 'mobile') {
        this.subscriptionSuccessMessage = this.translate.instant('HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.UNSUBSCRIBED_TEXT_UPDATES');
      } else if (this.selectedTab === 'email') {
        this.subscriptionSuccessMessage = this.translate.instant('HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.UNSUBSCRIBED_EMAIL_UPDATES');
      }
      this.subscriptionForm.reset();
      this.subscriptionFailed = false;
      this.subscriptionFailedMessage = '';
      this.showSubscriptionSuccessMessage = true;
      this.showUnsubscribeButton = false;
      this.showSubscribeButton = true;
      this.showSubscriptionForm = false;
      this.showUnsubscriptionForm = false;

    }, (err: any) => {
      let message = '';

      if (err.status > 499 || err.status < 400) {
        message = this.translate.instant('HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.UNKNOWN_ERROR');
      } else {
        message = err.error.message || "";
      }

      this.subscriptionFailed = true;
      this.subscriptionFailedMessage = message;
      this.showSubscriptionSuccessMessage = true;
      this.showUnsubscribeButton = false;
      this.showSubscribeButton = true;
      this.showSubscriptionForm = false;
      this.showUnsubscriptionForm = false;
    });
  }

  subscribeBulkOrders(formDetails: any, orderIdList: string[],) {
    let data = this.selectedTab === 'email' ? formDetails.controls.email.value : formDetails.controls.mobile.value;
    let body = {
      data: data,
      type: this.selectedTab === 'mobile' ? 'sms' : 'email',
      orderIds: orderIdList
    };

    this.subcriptionService.subscribeBulkOrdersToUpdates(body).subscribe((response: any) => {
      if (response.data.every((res: any) => (res.message === "already subscribed"))) {
        this.subscriptionSuccessMessage = this.selectedTab === 'mobile' 
          ? this.translate.instant(`HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.${this.orderTypeConverted.toUpperCase()}_MOBILE_ALREADY_SUBSCRIBED`)
          : this.translate.instant(`HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.${this.orderTypeConverted.toUpperCase()}_EMAIL_ALREADY_SUBSCRIBED`);
      } else {
        let tabValue = this.selectedTab === 'mobile' ? 'text' : this.selectedTab;
        this.subscriptionSuccessMessage = this.translate.instant(`HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.${this.orderTypeConverted.toUpperCase()}_${tabValue.toUpperCase()}_SIGNED_UP`);
      }

      this.showSubscriptionSuccessMessage = true;
      this.showUnsubscribeButton = true;
      this.showSubscribeButton = false;
      this.showSubscriptionForm = false;
      this.subscriptionFailedMessage = '';
      this.subscriptionFailed = false;
    }, (err: any) => {
      let message = '';

      if (err.status > 499 || err.status < 400) {
        message = this.translate.instant('HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.UNKNOWN_ERROR');
      } else {
        message = err.error.message || "";
      }

      this.subscriptionFailed = true;
      this.subscriptionFailedMessage = message;
      this.showSubscriptionSuccessMessage = false;
      this.showSubscribeButton = false;
    });
  }

  unsubscribeBulkOrders(formDetails: any) {
    let data = this.selectedTab === 'email' ? formDetails.controls.email.value : formDetails.controls.mobile.value;
    let body = {
      data: data,
      type: this.selectedTab === 'mobile' ? 'sms' : 'email',
      orderIds: this.orderIdList
    };
    this.subcriptionService.unsubscribeBulkOrdersToUpdates(body).subscribe((data: any) => {
      if (this.selectedTab === 'mobile') {
        this.subscriptionSuccessMessage = this.translate.instant('HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.UNSUBSCRIBED_TEXT_UPDATES');
      } else if (this.selectedTab === 'email') {
        this.subscriptionSuccessMessage = this.translate.instant('HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.UNSUBSCRIBED_EMAIL_UPDATES');
      }
      this.subscriptionForm.reset();
      this.subscriptionFailed = false;
      this.subscriptionFailedMessage = '';
      this.showSubscriptionSuccessMessage = true;
      this.showUnsubscribeButton = false;
      this.showSubscribeButton = true;
      this.showSubscriptionForm = false;
      this.showUnsubscriptionForm = false;

    }, (err: any) => {
      let message = '';

      if (err.status > 499 || err.status < 400) {
        message = this.translate.instant('HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.UNKNOWN_ERROR');
      } else {
        message = err.error.message || "";
      }

      this.subscriptionFailed = true;
      this.subscriptionFailedMessage = message;
      this.showSubscriptionSuccessMessage = true;
      this.showUnsubscribeButton = false;
      this.showSubscribeButton = true;
      this.showSubscriptionForm = false;
      this.showUnsubscriptionForm = false;
    });
  }
}

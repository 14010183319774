import { Pipe, PipeTransform } from '@angular/core';
import { StatusGroupController, StatusMasterController, StatusGroupInterface, StatusMasterInterface } from '@deliverysolutions/order-status-mapping';

@Pipe({
  name: 'orderStatusMapping'
})
export class OrderStatusMappingPipe implements PipeTransform {
  static getStatusDisplayName(status: string, statusGrp: any[]): string {
    let statusToDisplay = statusGrp.find(st => st.status === status);

    if (statusToDisplay) {
      return statusToDisplay.displayName;
    } else {
      // ORDER_CREATED is not mapped inn status library
      if (status === 'ORDER_CREATED') {
        return 'Order Created';
      }
      let statusMaster = new StatusMasterController();
      let statusFound = statusMaster.getStatusDataByStatus(status);

      return statusFound ? statusFound.displayName : OrderStatusMappingPipe.constructDisplayNameForNotFoundStatuses(status);
    }
  }

  static getStatusesByType(type?: string) {
    let statusGroup = new StatusGroupController();
    if (!type) {
      let allStatus = statusGroup.getAllStatuses();
      let mappings: any[] = [];
      allStatus.forEach(status => {
        mappings = [...mappings, ...(status.statusMapping)];
      });
      return mappings;
    }
    return statusGroup.getAllStatuses(type);
  }

  transform(value: any[] | string, ...args: any[]) {
    let mappings = OrderStatusMappingPipe.getStatusesByType(args[0]);

    if (mappings.length) {
      mappings = [...mappings[0].statusMapping];
    }

    if (args[1] === 'statusInfo' && typeof value === "string") {
      return OrderStatusMappingPipe.getStatusDisplayName(value, mappings);
    }

    // if type is not provided, get the displayNames as per the master
    if (!args[0]) {
      let statusMater = new StatusMasterController();
      for (let st of value) {
        let status = statusMater.getStatusDataByStatus(st.status);
        if (!status && st.status === 'ORDER_CREATED') {
          st.status = 'Order Created';
          continue;
        }
        st.status = status && status.displayName || "";
      }
      return value;
    }

    for (let st of value) {
      st.status = OrderStatusMappingPipe.getStatusDisplayName(st.status, mappings);
    }
    return value;
  }


  static constructDisplayNameForNotFoundStatuses(status: string) {
    let splitStatus = status.split('_');

    for (let i = 0; i < splitStatus.length; i++) {
      splitStatus[i] = splitStatus[i].charAt(0).toUpperCase() + splitStatus[i].substr(1).toLowerCase();
    }

    return splitStatus.join(" ");
  }
}

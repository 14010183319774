import { Input } from "@angular/core";
import { Component, OnInit } from '@angular/core';
import { AdConfig } from "../../ts-interfaces/hosted-tracking-config";

@Component({
  selector: 'ds-wide-ads-banner',
  templateUrl: './wide-ads-banner.component.html',
  styleUrls: ['./wide-ads-banner.component.scss']
})
export class WideAdsBannerComponent implements OnInit {
  @Input() secondaryAdsOne: AdConfig | undefined;
  @Input() secondaryAdsTwo: AdConfig | undefined;
  @Input() component: string | undefined;


  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import {
  Resolve,
} from '@angular/router';
import { Observable } from 'rxjs';
import { OrderService } from "../order.service";

@Injectable({
  providedIn: 'root'
})
export class OrderDetailsResolver implements Resolve<boolean> {

  constructor(private orderService: OrderService) { }
  resolve(): Observable<any | undefined | "NOT_FOUND"> {
    return this.orderService.get();
  }
}

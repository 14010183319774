export const SystemStatus = [
  'REQUEST_RECEIVED',
  'ESTIMATES_RECEIVED',
  'PENDING_DISPATCH',
  'PENDING_RELEASE',
  'PENDING_BATCHING',
  'PENDING_AUTORETRY',
  'PENDING_RETRY',
  'PENDING_CANCEL',
  'ESTIMATES_FAILED',
  'ORDER_FAILED',
  'ORDER_CANCELLED',
  'ORDER_UNASSIGNED',
  'ORDER_UNDELIVERABLE',
  'ORDER_EXCEPTION',
  'ERROR_EXCEPTION',
  'PICKUP_EXCEPTION'
];

import { Injectable } from '@angular/core';
import { HostedFeedbackConfig, HostedTrackingConfig } from '../ts-interfaces/hosted-tracking-config';

@Injectable({
  providedIn: 'root'
})
export class ClevertapAnalyticsService {

  constructor() { }

  setupClevertapAnalytics(hostedTrackingConfig: HostedTrackingConfig | HostedFeedbackConfig) {
    if (!hostedTrackingConfig.trackingTools) {
      return;
    }

    const ctAnalytics = hostedTrackingConfig.trackingTools.find(item => item.id === "clevertap");

    // in case clevertap analytics is not configured for a brand
    if (ctAnalytics && ctAnalytics.value) {
      const ctScript = document.createElement('script');
      ctScript.setAttribute("nonce", "ctScript1");
      ctScript.innerHTML = `var clevertap = { event: [], profile: [], account: [], onUserLogin: [], notifications: [], privacy: [] };
        clevertap.account.push({ "id": '${ctAnalytics.value}' });
        clevertap.privacy.push({ optOut: false });
        clevertap.privacy.push({ useIP: false });
        (function () {
          var wzrk = document.createElement('script');
          wzrk.type = 'text/javascript';
          wzrk.async = true;
          wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/a.js';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wzrk, s);
        })();`

      document.head.appendChild(ctScript);
    }
  }
}

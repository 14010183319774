export const listOfLines = [
    'Your order includes items containing alcohol. You may be required to present an ID for age verification.',
    'Your order contains tobacco. You may be required to present an ID for age verification.',
    'Your order contains prescription drugs. You may be required to present relevant prescriptions for verification.'
];
export const listOfItems = ['alcohol', 'tobacco', 'prescription drugs'];
export const listOfLinesKey = [
	'GENERIC.COMMON_PAGES.CONTAINS_ALCOHOL',
	'GENERIC.COMMON_PAGES.CONTAINS_TOBACCO',
	'GENERIC.COMMON_PAGES.CONTAINS_PRESCRIPTION_DRUGS',
];
export const listOfLinesID = [
	'GENERIC.COMMON_PAGES.REQUIRED_ID',
	'GENERIC.COMMON_PAGES.REQUIRED_ID',
	'GENERIC.COMMON_PAGES.REQUIRED_PRESCRIPTION',
];

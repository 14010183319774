import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BaseService } from './base-service';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubcriptionService extends BaseService {
  public isPreview: boolean = false;
  public fakeSuccess$ = new BehaviorSubject({ message: 'success' });

  constructor(private httpClient: HttpClient) {
    super();
    this.isPreview = window.location.pathname.includes('preview');
  }

  /**
   * body should contain orderIds array with string of orderId to be subscribed
   * @param body
   */
  subscribeBulkOrdersToUpdates(body: any) {
    if (!this.isPreview) {
      return this.httpClient.post(`${this.serverRootPathV2}subscription/subscribe/bulk`, body);
    } else {
      return of(this.fakeSuccess$);
    }
  }

  /**
   * body should container orderIds array with string of orderId to be unsubscribed
   * @param body
   */
  unsubscribeBulkOrdersToUpdates(body: any) {
    if (!this.isPreview) {
      return this.httpClient.post(`${this.serverRootPathV2}subscription/unsubscribe/bulk`, body);
    } else {
      return of(this.fakeSuccess$);
    }
  }

  subscribeToUpdates(body: any, orderID: any) {
    if (!this.isPreview) {
      return this.httpClient.post(`${this.serverRootPathV2}subscription/subscribe/${orderID}`, body);
    } else {
      return of(this.fakeSuccess$);
    }
  }

  unsubscribeToUpdates(body: any, orderID: any) {
    if (!this.isPreview) {
      return this.httpClient.post(`${this.serverRootPathV2}subscription/unsubscribe/${orderID}`, body);
    } else {
      return of(this.fakeSuccess$);
    }
  }

}

<div *ngIf="!hostedFeedbackConfig || (hostedFeedbackConfig && !hostedFeedbackConfig.active) ">
  <ds-service-unavailable headerTitle="{{ 'HOSTED_FEEDBACK.FEEDBACK_COMPONENT.SERVICE_UNAVAILABLE_TEXT' | translate }}"></ds-service-unavailable>
</div>
<div *ngIf="hostedFeedbackConfig && hostedFeedbackConfig.active">
  <!-- Header section start -->
  <div class="nav-cnt" *ngIf="hostedFeedbackConfig">
    <div class="container-fluid max-950px">
      <div class="row">
        <div class="col">
          <ds-site-header [header]="hostedFeedbackConfig.components.header!"
            [businessLogos]="hostedFeedbackConfig?.branding?.businessLogos!" [isBackgroundWhite]="isNavBackgroundWhite" [captureEvent]="'click-hosted-feedback-logo'">
          </ds-site-header>
        </div>
      </div>
    </div>
  </div>
  <!-- Header section end -->

  <!-- Broadcast section start -->
  <div class="container-fluid max-950px">
    <div class="row">
      <div class="col">
        <ds-broadcast-message *ngIf="hostedFeedbackConfig?.components?.broadcast?.active"
          [hostedTrackingConfig]="hostedFeedbackConfig" [component]="'hosted-feedback'"></ds-broadcast-message>
      </div>
    </div>
  </div>
  <!-- Broadcast section end -->

  <div class="custom-row justify-content-around pb-14px">
    <div class="col pb-2 pt-3">
      <h2 class="text-center"><span>{{ 'HOSTED_FEEDBACK.FEEDBACK_COMPONENT.HELP_IMPROVE_TEXT' | translate }}</span></h2>
    </div>
  </div>

  <div class="container-fluid max-950px">
    <div class="custom-row justify-content-around">
      <div class="custom-col-md-6 pb-14px" *ngIf="fetchedOrder">
        <ds-feedback-box [orderIdList]="[fetchedOrder._id]" [orderType]="fetchedOrder.type"
          [isFeedbackShared]="fetchedOrder.isFeedbackShared" [feedback]="fetchedOrder.feedback" [autoSave]="autoSaveFeedback" source="hosted-feedback">
        </ds-feedback-box>
      </div>
      <div class="custom-col-md-6 pb-14px temp-unavlble" *ngIf="!fetchedOrder">
        <div class="v-items">
          <h3 class="text-center">{{ 'HOSTED_FEEDBACK.FEEDBACK_COMPONENT.TEMPORARY_UNAVAILABLE_HEADER' | translate }}</h3>
          <p class="text-center">{{ 'GENERIC.COMMON_PAGES.SERVICE_UNAVAILABLE_BODY' | translate }}, {{ 'GENERIC.COMMON_PAGES.CHECK_BACK_SOON' | translate | lowercase }}.</p>
        </div>
      </div>
      <div *ngIf="adsToShow" class="custom-col-md-6 pb-14px">
        <ds-side-ad-banner [adconfig]="adsToShow" [largerHeight]="true" [captureEvent]="'click-hosted-feedback-primary-banner'">
        </ds-side-ad-banner>
      </div>
    </div>
  </div>

  <!-- recommendations start -->
  <div class="container-fluid max-950px pb-14px" *ngIf="recommendations && recommendations.length">
    <div class="row">
      <div class="col">
        <ds-recommendations [recommendations]="recommendations"></ds-recommendations>
      </div>
    </div>
  </div>
  <!-- recommendations end-->


  <!-- Second ad banner start -->
  <div class="container-fluid max-950px" *ngIf="secondaryAdsOne || secondaryAdsTwo">
    <div class="row">
      <div class="col">
        <ds-wide-ads-banner [secondaryAdsOne]="secondaryAdsOne" [secondaryAdsTwo]="secondaryAdsTwo"
          [component]="'hosted-feedback'">
        </ds-wide-ads-banner>
      </div>
    </div>
  </div>
  <!-- Second ad banner end-->

  <!-- footer start-->
  <div class="container-fluid max-950px mt-3 mb-5">
    <div class="row">
      <div class="col">
        <ds-site-footer [order]="fetchedOrder" [tenantId]="hostedFeedbackConfig?.tenantId"></ds-site-footer>
      </div>
    </div>
  </div>
  <!-- footer end-->


  <!-- Need Help start -->
  <a class="need-help-section f-16" [href]="hostedFeedbackConfig?.components?.needHelp?.helpUrl"
    *ngIf="hostedFeedbackConfig?.components?.needHelp?.helpUrl" target="_blank" [captureEvent]="'click-hosted-feedback-help-menu'">
    <span class="question-mark">?</span>&nbsp;<span class="needhelp-txt">
      {{ hostedFeedbackConfig?.components?.needHelp?.displayText || ('HOSTED_FEEDBACK.FEEDBACK_COMPONENT.NEED_HELP' | translate) }}
    </span>
  </a>
  <!-- Need Help end -->
</div>
<ds-cookie-consent *ngIf="fetchedOrder" [order]="fetchedOrder"></ds-cookie-consent>

<!-- in-store-pickup/curbside/pickup component -->
<div class="row"
  *ngIf="order.type === orderTypeController['IN_STORE_PICKUP'] || order.type === orderTypeController.CURBSIDE">
  <div class="col">
    <ds-pickup-order-details [order]="order" [hostedTrackingConfig]="hostedTrackingConfig" [isSystemStatus]="isSystemStatus"
      [showSiblingOrders]="showSiblingOrders" [isTerminalStatus]="isTerminalStatus" (openDeliveryProofsModal)="openModal(content)">
    </ds-pickup-order-details>
  </div>
</div>

<!-- shipment component -->
<div class="row" *ngIf="order.type === orderTypeController.SHIPPING">
  <div class="col">
    <ds-shipment-order-details [order]="order" [hostedTrackingConfig]="hostedTrackingConfig"
      [isSystemStatus]="isSystemStatus" [parentOrder]="parentOrder" [showSiblingOrders]="showSiblingOrders"
      (openDeliveryProofsModal)="openModal(content)">
    </ds-shipment-order-details>
  </div>
</div>

<!-- delivery order component -->
<div class="row" *ngIf="!order.type || order.type === orderTypeController.DELIVERY">
  <div class="col">
    <ds-delivery-order-details [order]="order" [hostedTrackingConfig]="hostedTrackingConfig"
      [isSystemStatus]="isSystemStatus" [isNegativeStatus]="isNegativeStatus" [showSiblingOrders]="showSiblingOrders" (openDeliveryProofsModal)="openModal(content)">
    </ds-delivery-order-details>
  </div>
</div>

<!-- subscribe, feedback widget -->
<div class="custom-row">
  <div *ngIf="isSubscribeBoxVisible" class="custom-col-12 pb-14px" [ngClass]="{'custom-col-md-6': isFeedbackBoxVisible}">
    <ds-subscribe-to-updates [orderIdList]="[order._id]" [orderType]="order.type"
      [subscribeToUpdateOptions]="subscribeToUpdateOptions"></ds-subscribe-to-updates>
  </div>
  <div class="custom-col-12 pb-14px" *ngIf="isFeedbackBoxVisible" [ngClass]="{'custom-col-md-6': isSubscribeBoxVisible}">
    <ds-feedback-box [orderIdList]="[order._id]" [orderType]="order.type" [isFeedbackShared]="order.isFeedbackShared"
      [feedback]="order.feedback" [isTerminalStatus]="isTerminalStatus" source="hosted-tracking"></ds-feedback-box>
  </div>
</div>

<div class="row pb-14px" *ngIf="isPackageItemBoxVisible">
  <div class="col">
    <ds-package-item-list [itemList]="packageItemList" [returnsUrl]="returnsUrl"
      [hostedTrackingConfig]="hostedTrackingConfig"></ds-package-item-list>
  </div>
</div>

<ng-template #content let-d="dismiss">
  <div class="modal-header">
    <span class="close" (click)="d()" aria-hidden="true">&times;</span>
  </div>
  <div class="modal-body">
    <ds-images-modal [images]="images"></ds-images-modal>
  </div>
</ng-template>

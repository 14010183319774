<router-outlet (activate)="onActivate($event)">
</router-outlet>

<div class="spinner d-flex align-items-center justify-content-center" *ngIf="isPageLoading">
  <div class="lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

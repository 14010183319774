import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from './base-service';
import { HostedFeedbackConfig, HostedTrackingConfig } from '../ts-interfaces/hosted-tracking-config';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class HostedTrackingConfigService extends BaseService {
  public hostedTrackingConfig: HostedTrackingConfig | undefined = undefined;
  public hostedFeedbackConfig: HostedFeedbackConfig | undefined = undefined;

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  public fetchHostedTrackingConfig(brandExternalId: string | undefined = undefined, customDomain?: string) {
    // check cache
    if (this.hostedTrackingConfig) {
      return of(this.hostedTrackingConfig);
    }

    let httpparams: any = {
      a: true
    };

    if (brandExternalId) {
      httpparams['brandExternalId'] = brandExternalId;
    }

    if (customDomain) {
      httpparams['customDomain'] = customDomain;
    }

    return this.httpClient.get(`${this.serverRootPathV2}hosted-tracking/config`, { params: httpparams })
      .pipe(
        tap((val: any) => {
          if (environment.env === "local") {
            val.googleMapsApiKey = environment.googleMapsAPIKey;
          } else if (val.googleMapsApiKey) {
            const decryptedMapsApiKey = this.decryptMapsApiKey(val.googleMapsApiKey);
            val.googleMapsApiKey = decryptedMapsApiKey;
          }
          this.hostedTrackingConfig = val
        }),
        catchError(e => { console.log('error in fetching config', e); return of(undefined) }));
  }

  public fetchHostedFeedbackConfig(brandExternalId: string | undefined = undefined, customDomain?: string) {
    // check cache
    if (this.hostedFeedbackConfig) {
      return of(this.hostedFeedbackConfig);
    }

    let httpparams: any = {
      a: true
    };

    let headers: any = {};

    if (brandExternalId) {
      httpparams['brandExternalId'] = brandExternalId;
      headers["brandExternalId"] = brandExternalId;
    }

    if (customDomain) {
      httpparams['customDomain'] = customDomain;
    }

    return this.httpClient.get(`${this.serverRootPathV2}hosted-feedback/config`, { params: httpparams })
      .pipe(
        tap((val: any) => this.hostedFeedbackConfig = val),
        catchError(e => { console.log('error in fetching config', e); return of(undefined) }));
  }

  decryptMapsApiKey(googleMapsApiKey: string): string {
    return CryptoJS.AES.decrypt(googleMapsApiKey, environment.googleMapsApiKeyDecryptionPassphrase).toString(CryptoJS.enc.Utf8);
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from "@angular/router";
import { HostedFeedbackConfig, AdConfig, Recommendations, FeedbackVisibility, CommunicationVisibility } from "../../ts-interfaces/hosted-tracking-config";
import { OrderInterface } from "../../ts-interfaces/order-interface";
import { DARK, DARK_CONSTRAST_COLOR, LIGHT, NOT_FOUND, TRACK_CONSTRAST_COLOR } from "../../shared/constants/generic-constants";
import { contrastColorOf } from "../../shared/functions/generate-contrast-color";
import { TerminalStatus } from 'src/app/shared/constants/terminal-status';
import { SystemStatus } from 'src/app/shared/constants/system-status';
import { OrderService } from "../../services/order.service";
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ds-preview-hf',
  templateUrl: './preview-hosted-feedback.component.html',
  styleUrls: ['./preview-hosted-feedback.component.scss']
})
export class PreviewHostedFeedbackComponent implements OnInit, OnDestroy {
  public hostedFeedbackConfig!: HostedFeedbackConfig;
  public secondaryAdsOne!: AdConfig | undefined;
  public secondaryAdsTwo!: AdConfig | undefined;
  public primaryAds: AdConfig | undefined;
  public order: OrderInterface | undefined;
  public fetchedOrder: OrderInterface | undefined;
  isTerminalStatus: boolean = false;
  isSystemStatus: boolean = false;
  private streamSource: any;
  private validationToken: string | undefined;
  public isPageLoading: boolean = true;
  public recommendations!: Recommendations[] | undefined;
  public liveStreaming = false;
  subscribeToUpdateVisibility!: CommunicationVisibility;
  isSubscribeBoxVisible = false;
  feedbackVisibility!: FeedbackVisibility;
  isFeedbackBoxVisible: boolean = false;
  adsToShow!: AdConfig | undefined;

  constructor(
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.addListener();
  }

  private addListener() {
    this.validationToken = this.activatedRoute.snapshot.queryParams.valToken;
    if (window.addEventListener) {
      window.addEventListener("message", this.receiveMessage);
    } else {
      (<any>window).attachEvent("onmessage", this.receiveMessage);
    }
  }

  private receiveMessage = (event: MessageEvent) => {
    if (environment.appUrl.includes(event.origin) || true) {
      const formValue = event.data;
      if (formValue) {
        this.hostedFeedbackConfig = formValue;
        this.order = this.orderService.getFakeOrder();
        if (!this.order || !this.hostedFeedbackConfig) {
          return;
        }
        this.fetchedOrder = this.order;
        this.setupTheme();
        this.setUpAdsConfig();
        this.setPageTitle();
        this.setFontVariables();
        this.setupRecommendations();
        this.isPageLoading = false;
        window.removeEventListener("message", this.receiveMessage);
      }
      if (!(event.source instanceof MessagePort) && !(event.source instanceof ServiceWorker)) {
        event?.source?.postMessage(this.validationToken, event.origin);
      }
    }
  }

  openModal(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'custom-class'
    });
  }

  private setupRecommendations() {
    if (!this.hostedFeedbackConfig?.components?.recommendations) {
      return;
    } else if (this.hostedFeedbackConfig.components.recommendations.url
    ) {
      const sampleRecommendationsJson = [
        {"imageUrl":"assets/images/preview-recommendation-1.jpg","productName":"Product 1","productUrl":""},
        {"imageUrl":"assets/images/preview-recommendation-2.jpg","productName":"Product 2","productUrl":""},
        {"imageUrl":"assets/images/preview-recommendation-3.jpg","productName":"Product 3","productUrl":""},
        {"imageUrl":"assets/images/preview-recommendation-4.jpg","productName":"Product 4","productUrl":""},
      ];
      this.recommendations = sampleRecommendationsJson;
    } else if (this.hostedFeedbackConfig.components.recommendations.jsonFileId){
      this.recommendations = this.hostedFeedbackConfig.components.recommendations.jsonFileId;
    }
  }

  private setUpAdsConfig() {
    // don't really need to put this check, but stupid typescript does not know that
    // I have handled the case when this variable is undefined. Sadly we cannot fix developers with tools.
    if (!this.hostedFeedbackConfig) {
      return;
    }

    this.secondaryAdsOne = this.hostedFeedbackConfig.components.ads.find(ads => {
      return ads.active && ads.type === "secondaryOne" && ads.url && ads.clickthroughLink;
    });

    this.secondaryAdsTwo = this.hostedFeedbackConfig.components.ads.find(ads => {
      return ads.active && ads.type === "secondaryTwo" && ads.url && ads.clickthroughLink;
    });

    this.primaryAds = this.hostedFeedbackConfig.components.ads.find(ads => {
      return ads.active && ads.type === "primary" && ads.url && ads.clickthroughLink;
    });

    this.adsToShow = this.primaryAds;

  }

  private setupTheme() {
    // same reason I have put this check in setUpAdsConfig
    if (!this.hostedFeedbackConfig) {
      return;
    }

    if (this.hostedFeedbackConfig.branding?.color?.primary) {
      const contrastColor = contrastColorOf(this.hostedFeedbackConfig.branding.color.primary);
      if (contrastColor === DARK_CONSTRAST_COLOR) {
        document.body.setAttribute(TRACK_CONSTRAST_COLOR, DARK);
      } else {
        document.body.setAttribute(TRACK_CONSTRAST_COLOR, LIGHT);
      }

      // primary color for site
      document.documentElement.style
        .setProperty('--primary', this.hostedFeedbackConfig.branding.color.primary);
      // when you use primary as background use this as font color
      document.documentElement.style
        .setProperty('--primary-contrast', contrastColor); // contrasting black or white
    } else {
      // default primary color for site, when no primary color is given
      document.documentElement.style
        .setProperty('--primary', '#122344');
      // when you use primary as background use this as font color
      document.documentElement.style
        .setProperty('--primary-contrast', '#cccccc'); // contrasting black or white
    }

    // for site nav
    if (this.hostedFeedbackConfig.components.header?.fontColor) {
      document.documentElement.style
        .setProperty('--nav-primary', this.hostedFeedbackConfig.components.header.fontColor);
    }
    if (this.hostedFeedbackConfig.components.header?.fontSize) {
      document.documentElement.style
        .setProperty('--nav-size', this.hostedFeedbackConfig.components.header.fontSize + 'px');
    }
    if (this.hostedFeedbackConfig.components.header?.backgroundColor) {
      document.documentElement.style
        .setProperty('--nav-bg', this.hostedFeedbackConfig.components.header.backgroundColor);

      const navBgContrast = contrastColorOf(this.hostedFeedbackConfig.components.header.backgroundColor);

      document.documentElement.style
        .setProperty('--nav-bg-contrast', navBgContrast);
    }
  }

  private setPageTitle() {
    // when we have order details show both order id and brand name
    // otherwise just show brand name
    if (this.order) {
      document.title = `${this.translate.instant('GENERIC.PLATFORM.ORDER_TEXT')} #${this.order.orderExternalId} | ${this.order.brandName}`;
    } else if (this.hostedFeedbackConfig) {
      document.title = this.hostedFeedbackConfig.brandName;
    } else {
      document.title = this.translate.instant('GENERIC.COMMON_PAGES.PAGE_NOT_FOUND_TEXT');
    }
  }

  private setFontVariables() {
    if (!this.hostedFeedbackConfig) {
      return;
    }

    let content = "", heading = "", nav = "";

    if (this.hostedFeedbackConfig.branding.fonts?.content) {
      const contentFontType = this.hostedFeedbackConfig.branding.fonts.content.split('.').pop();

      content = `@font-face {
        font-family: 'content_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${this.hostedFeedbackConfig.branding.fonts.content}) format('${contentFontType}');
      }`;
    }

    if (this.hostedFeedbackConfig.branding.fonts?.heading) {
      const headingFontType = this.hostedFeedbackConfig.branding.fonts.heading.split('.').pop();

      heading = `@font-face {
        font-family: 'heading_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${this.hostedFeedbackConfig.branding.fonts.heading}) format('${headingFontType}');
      }`;
    }

    if (this.hostedFeedbackConfig.branding.fonts?.navigation) {
      const navFontType = this.hostedFeedbackConfig.branding.fonts.navigation.split('.').pop();

      nav = `@font-face {
        font-family: 'nav_font';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${this.hostedFeedbackConfig.branding.fonts.navigation}) format('${navFontType}');
      }`;
    }

    const style = document.createElement('style');
    style.innerHTML = `
      ${content}
      ${heading}
      ${nav}
    `;

    document.head.appendChild(style);
  }

  public ngOnDestroy() {
    if (this.streamSource) {
      this.streamSource.close();
    }
  }
}

<div class="row">
  <h1 *ngIf="showSiblingOrders" class="col orderIdTitle my-4">
    {{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} #{{order.groupId}} ( {{ 'GENERIC.PLATFORM.PACKAGE' | translate }} #{{order.orderExternalId}} )
  </h1>
  <h1 *ngIf="!showSiblingOrders" class="col orderIdTitle my-4">
    {{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} #{{order.orderExternalId}}
  </h1>
</div>


<!-- Compliance section start -->
<div class="row">
  <div class="col">
    <app-compliance-info-box-section *ngIf="hostedTrackingConfig?.componentVisibility?.showComplianceInfo" [order]="order"
      [hostedTrackingConfig]="hostedTrackingConfig!"></app-compliance-info-box-section>
  </div>
</div>
<!-- Compliance section end --> 

<div class="custom-row align-items-stretch">
  <div class="custom-col-12 custom-col-md-6 pb-14px">
    <ds-order-status-card *ngIf="!isSystemStatus" [order]="order"
      (detailsButtonClicked)="openDeliveryProofsModal.emit(true)"></ds-order-status-card>
    <ds-info-unavailable *ngIf="isSystemStatus"></ds-info-unavailable>
  </div>
  <div class="custom-col-12 custom-col-md-6 pb-14px d-none d-md-block desk-view-primary-Ad">
    <ds-side-ad-banner [adconfig]="primaryAds" *ngIf="primaryAds"></ds-side-ad-banner>
  </div>
</div>


<div class="custom-row">
  <div class="custom-col-12 text-lg-left pb-14px">
    <!-- Pickup Status start -->
    <div class="pickup-status-container">
      <div class="pickup-status d-flex flex-column">
        <div class="d-flex flex-column flex-md-row flex-1-1-auto h-372px border-bottom-1px position-relative">
          <div class="h-100 d-md-block d-none border-right-1px">
            <!-- desktop view -->
            <ds-status-history [timeZone]="order.timeZone" [_statusHistory]="order.statusHistory" [orderType]="order.type"
              [desktopMode]="true" [attachments]="order.attachments" (detailsButtonClicked)="openDeliveryProofsModal.emit(true)">
            </ds-status-history>
          </div>
        
          <!-- google map -->
          <div class="flex-1-1-auto">
            <ds-google-map [order]="order"></ds-google-map>
          </div>
        </div>
        <!-- mobile view -->
        <div class="d-md-none d-block border-bottom-1px">
          <ds-status-history [timeZone]="order.timeZone" [_statusHistory]="order.statusHistory" [orderType]="order.type"
            [attachments]="order.attachments" (detailsButtonClicked)="openDeliveryProofsModal.emit(true)"></ds-status-history>
        </div>
        <ds-store-driver-info [order]="order" [hostedTrackingConfig]="hostedTrackingConfig"></ds-store-driver-info>
        <div class="apps-container px-3">
          <div class="instructions-container d-none" *ngIf="order.pickupInstructions">
            <div class="update-text">
              {{ 'GENERIC.PLATFORM.INSTRUCTIONS' | translate }}
            </div>
            <ul class="instructions pt-0 m-0">
              <li>
                {{order.pickupInstructions}}
              </li>
            </ul>
          </div>
          <div class="check-in-container text-center d-md-none mb-3" *ngIf="!isTerminalStatus">
            <div class="on-the-way mb-3">
              {{ 'GENERIC.COMMON_PAGES.ON_YOUR_WAY' | translate }}
            </div>
            <div class="submit-button">
              <a role="button" class="btn btn-primary btn-block mx-auto" [href]="order.customerWebflowUrl"
                target="_blank">
                {{ 'GENERIC.COMMON_PAGES.CHECK_IN' | translate | uppercase }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Pickup Status end -->
  </div>
  <div class="custom-col-12 custom-col-md-6 pb-3 d-md-none mob-view-primary-Ad pb-14px">
    <ds-side-ad-banner [adconfig]="primaryAds" *ngIf="primaryAds"></ds-side-ad-banner>
  </div>
</div>

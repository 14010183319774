import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ds-images-modal',
  templateUrl: './images-modal.component.html',
  styleUrls: ['./images-modal.component.scss'],
  providers: [NgbCarouselConfig]
})
export class ImagesModalComponent implements OnInit {

  @Input() images: string[] = [];
  @ViewChild('imagesContainer', { read: ElementRef, static: false })
  public imagesContainer: any;
  @ViewChild('carousel') carousel: any;
  imageDimensions = {
    height: '400px', width: '408px'
  };
  public slideIndex = 0;

  constructor(config: NgbCarouselConfig) {
    config.wrap = false;
    config.showNavigationIndicators = false;
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.imageDimensions = {
      height: `${this.imagesContainer.nativeElement.offsetHeight - 50}px`,
      width: `${this.imagesContainer.nativeElement.offsetWidth}px`
    };

    if (this.images.length === 1) {
      const nextButton = document.querySelector('.carousel-control-next');
      if (nextButton) {
        nextButton.classList.add('disabled');
      }
    }
    if (this.slideIndex === 0) {
      const prevButton = document.querySelector('.carousel-control-prev');
      if (prevButton) {
        prevButton.classList.add('disabled');
      }
    }

    this.carousel.slide.subscribe((slideEvent: any) => {
      if (slideEvent.direction === 'start') {
        this.slideIndex = this.slideIndex + 1;
      } else if (slideEvent.direction === 'end') {
        this.slideIndex = this.slideIndex - 1;
      }
      if (this.slideIndex === 0) {
        // Add class to disable previous button
        const prevButton = document.querySelector('.carousel-control-prev');
        if (prevButton) {
          prevButton.classList.add('disabled');
        }
      } else {
        // Remove class from previous button
        const prevButton = document.querySelector('.carousel-control-prev');
        if (prevButton) {
          prevButton.classList.remove('disabled');
        }
      }
      if (this.slideIndex === this.images.length - 1) {
        // Add class to disable next button
        const nextButton = document.querySelector('.carousel-control-next');
        if (nextButton) {
          nextButton.classList.add('disabled');
        }
      } else {
        // Remove class from next button
        const nextButton = document.querySelector('.carousel-control-next');
        if (nextButton) {
          nextButton.classList.remove('disabled');
        }
      }
    });
  }
}

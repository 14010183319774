import { Component } from '@angular/core';
import { Router, ResolveEnd } from "@angular/router";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { environment } from "src/environments/environment";
import { Userpilot } from "userpilot";
import { TranslateService } from '@ngx-translate/core';

Userpilot.initialize(environment.userPilotAuthKey);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public isPageLoading = true;

  constructor(private router: Router) {
    // on first page load remove loading spinner
    this.router.events
      .subscribe(
        (event) => {
          if (event instanceof ResolveEnd) {
            this.isPageLoading = false;
          }
        }
      );
  }

  onActivate($event: any) {
    if ($event instanceof PageNotFoundComponent) {
      document.title = 'Page not found';
    }
  }
}

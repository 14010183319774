<div class="broadcast-message-container p-3 mt-4" *ngIf="showBCMessage"
  [captureEvent]="'click-'+component+'-broadcast'">
  <div class="d-flex justify-content-between align-items-center">
    <h2 class="f-18px m-0">{{broadcast.header }}</h2>
    <button class="btn" type="button" (click)="closeBCMessage()">&#10005;</button>
  </div>

  <div class="pt-2 message f-14 d-block d-md-none text-break" *ngIf="broadcast.body">
    {{
      show
        ? broadcast.body
        : (broadcast.body | slice: 0:70)
    }}<span *ngIf="(broadcast.body.length) > 70">...</span
    >
    <a class="ml-3" style="white-space: nowrap;"
      *ngIf="(broadcast.body.length) > 70"
      (click)="show = !show"
      >{{ (show ? 'GENERIC.COMMON_PAGES.READ_LESS' : 'GENERIC.COMMON_PAGES.READ_MORE') | translate }}</a
    >
  </div>

  <div class="pt-2 message f-14 d-none d-md-block text-break" *ngIf="broadcast.body">
    {{
      show
        ? broadcast.body
        : (broadcast.body | slice: 0:210)
    }}<span *ngIf="(broadcast.body.length) > 210">...</span
    >
    <a class="ml-3" style="white-space: nowrap;"
      *ngIf="(broadcast.body.length) > 210"
      (click)="show = !show"
      >{{ (show ? 'GENERIC.COMMON_PAGES.READ_LESS' : 'GENERIC.COMMON_PAGES.READ_MORE') | translate }}</a
    >
  </div>
</div>

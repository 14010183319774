<div class="custom-row justify-content-around">
  <div class="custom-col-12 custom-col-md-6 pb-14px" *ngIf="secondaryAdsOne && secondaryAdsOne.url">
    <!--Ad banner start-->
    <div class="ad-container"  [style.background-image]="'url(' + secondaryAdsOne.url + ')'">
      <a class="ads-link secondary-ads-link align-items-center d-flex position-relative" style="padding-top: 56.35%"
        [href]="secondaryAdsOne?.clickthroughLink" [captureEvent]="'click-'+component+'-secondary-ads-one-banner'" target="_blank"
        rel="noopener noreferrer">
        <img class="ad-img position-absolute" [src]="secondaryAdsOne.url" alt="{{ 'GENERIC.COMMON_PAGES.AD_BANNER_IMAGE_ALTERNATE_TEXT' | translate }}">
      </a>
    </div>
    <!--Ad Banner end-->
  </div>
  <div class="custom-col-12 custom-col-md-6" *ngIf="secondaryAdsTwo && secondaryAdsTwo.url">
    <!--Ad banner start-->
    <div class="ad-container" [style.background-image]="'url(' + secondaryAdsTwo.url + ')'">
      <a class="ads-link secondary-ads-link align-items-center d-flex position-relative" style="padding-top: 56.35%"
        [href]="secondaryAdsTwo?.clickthroughLink" [captureEvent]="'click-'+component+'-secondary-ads-two-banner'" target="_blank"
        rel="noopener noreferrer">
        <img class="ad-img position-absolute" [src]="secondaryAdsTwo.url" alt="{{ 'GENERIC.COMMON_PAGES.AD_BANNER_IMAGE_ALTERNATE_TEXT' | translate }}">
      </a>
    </div>
    <!--Ad Banner end-->
  </div>
</div>

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
} from '@angular/router';
import { Observable } from 'rxjs';
import { OrderService } from "../order.service";

@Injectable({
  providedIn: 'root'
})
export class OrderFeedbackDetailsResolver implements Resolve<boolean> {

  constructor(private orderService: OrderService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any | undefined | "NOT_FOUND"> {

    let orderId = route.params && route.params["orderId"] ? route.params["orderid"] : undefined;

    return this.orderService.getOrderFeedback(orderId);
  }
}

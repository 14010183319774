<div class="d-flex flex-column flex-md-row">
  <div class="driver-details section p-3 w-md-50 border-md-right"
    *ngIf="!isPickupOrder && isDriverActive && (order.driver || order.vehicle?.make || order.vehicle?.type || order.vehicle?.color)">
    <div class="section-title py-2 f-10px">
      {{ 'GENERIC.COMMON_PAGES.DELIVERY_BY' | translate | uppercase }}
    </div>

    <!-- actual details -->
    <div class="d-flex justify-content-between">
      <div class="d-inline-flex flex-1-1-auto align-driver-name">
        <img [src]="order.driver?.photo" default="/assets/icons/default-avatar.svg" width="40" height="40"
          class="rounded-circle">

        <div class="ml-2">
          <ng-container>
            <div *ngIf="order.driver" class="primary-data">{{order.driver.name}}</div>
            <div *ngIf="order.vehicle?.make || order.vehicle?.type || order.vehicle?.color" class="tertiary-data f-12px">
              <span *ngIf="order.vehicle?.make || order.vehicle?.type">
                {{order.vehicle?.make || order.vehicle?.type || ""}}
              </span>
              <span *ngIf="(order.vehicle?.make || order.vehicle?.type) && order.vehicle?.color">
                |
              </span>
              <span *ngIf="order.vehicle?.color">
                {{order.vehicle?.color}}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="d-inline-flex min-width-fit-content">
        <a class="img-container" *ngIf="isDriverLocationVisible && order.lastLocation" target="_blank"
          [href]="'https://www.google.com/maps/search/?api=1&query='+ order.lastLocation.latitude + ',' + order.lastLocation.longitude">
          <img height="20" width="20" src="/assets/icons/current-location.svg">
        </a>
        <a class="img-container ml-2" *ngIf="isDriverPhoneVisible && order.driver?.phone"
          [href]="'tel:' + order.driver?.phone">
          <img height="20" width="20" src="/assets/icons/phone-call.svg" alt="order.pickUpContact.phone">
        </a>
      </div>
    </div>
  </div>
  <div class="store-details section p-3" [ngClass]="{'w-md-50': isDriverActive && !isPickupOrder}">
    <div class="section-title py-2 f-10px">
      {{ 'GENERIC.COMMON_PAGES.PICKUP_FROM' | translate | uppercase }}
    </div>

    <!-- actual details -->
    <div class="d-flex flex-1-1-auto overflow-hidden justify-content-between">
      <div class="overflow-hidden d-inline-flex">
        <img [src]="hostedTrackingConfig.brandLogo" default="/assets/icons/defaultStore.svg" width="40" height="40"
          class="rounded-circle">

        <div class="d-inline-flex flex-column ml-2 flex-shrink-1 overflow-hidden">
          <div class="primary-data">{{order.store.name}}</div>
          <div class="tertiary-data text-ellipsis f-12px">{{storeAddress}}</div>
        </div>
      </div>
      <div class="d-inline-flex min-width-fit-content">
        <a class="img-container" *ngIf="isStoreLocationVisible && order.pickUpAddress.latitude" target="_blank"
          [href]="'https://www.google.com/maps/search/?api=1&query='+ order.pickUpAddress.latitude + ',' + order.pickUpAddress.longitude" [captureEvent]="'click-hosted-tracking-pickup-location-map'">
          <img height="20" width="20" src="/assets/icons/current-location.svg" alt="{{ 'GENERIC.COMMON_PAGES.STORE_LOCATION_ALT_TEXT' | translate }}">
        </a>
        <a class="img-container ml-2" *ngIf="isStorePhoneVisible && order.pickUpContact.phone && showPickupContactForTrackers"
          href="tel:{{order.pickUpContact.phone}}" [captureEvent]="'click-hosted-tracking-pickup-location-phone'">
          <img height="20" width="20" src="/assets/icons/phone-call.svg" alt="order.pickUpContact.phone">
        </a>
      </div>
    </div>
  </div>
</div>

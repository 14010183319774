import { Injectable } from '@angular/core';
import { EventSourcePolyfill } from 'event-source-polyfill/src/eventsource.min.js';

@Injectable({
  providedIn: 'root'
})
export class SseService {

  constructor() { }

  private getEventSource(url: string, opts: object) {
    return new EventSourcePolyfill(url, opts);
  }

  public getServerSentEvent(url: string, onChange: (arg0: {}) => void, onError: (arg0: any) => void, headers: any) {

    const eventSource = this.getEventSource(url, { headers });
    eventSource.onopen = (event: any) => { onChange({}); };
    eventSource.onmessage = (event: { data: string; }) => {
      if (event && event.data) {
        onChange(JSON.parse(event.data));
      }
    };
    eventSource.onerror = (event: { status: number; }) => {
      // double equals used intentionally, do not change
      // tslint:disable-next-line: triple-equals
      if (event && event.status == 204) {
        eventSource.close();
      }
      onError(event);
    };
    return eventSource;
  }

}

<div class="subscribe-updates-container" [ngClass]="{'h-100': shouldBeLarger, 'small-container': !shouldBeLarger}">
  <div class="title-container p-3">
    <h2 class="title mb-0">
      {{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.SUBSCRIBE_TO_UPDATES_TITLE' | translate }}
    </h2>
  </div>
  <nav>
    <div class="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
      <a *ngIf=" subscribeToUpdateOptions.channels.isActiveSms" class="nav-item nav-link nav-item-padding"
        (click)="changeTab('mobile')" [ngClass]="{'active': selectedTab === 'mobile'}" data-toggle="tab" role="tab"
        aria-controls="nav-authentication" aria-selected="true">
        <img src="../../../assets/icons/SMS.svg" class="icon-size">
      </a>
      <a *ngIf="subscribeToUpdateOptions.channels.isActiveEmail" class="nav-item nav-link nav-item-padding"
        (click)="changeTab('email')" [ngClass]="{'active': selectedTab === 'email'}" data-toggle="tab" role="tab"
        aria-controls="nav-authentication" aria-selected="true">
        <img src="../../../assets/icons/email.svg" class="icon-size">
      </a>
      <a *ngIf="subscribeToUpdateOptions.channels.isActiveWhatsapp"
        class="nav-item nav-link disabled nav-item-padding temp-gray-out" (click)="changeTab('whatsapp')"
        data-toggle="tab" role="tab" aria-controls="nav-authentication" aria-selected="true">
        <img src="../../../assets/icons/whatsapp.svg" class="icon-size">
      </a>
      <a *ngIf="subscribeToUpdateOptions.channels.isActiveFacebook"
        class="nav-item nav-link disabled nav-item-padding temp-gray-out border-right-0" (click)="changeTab('facebook')"
        data-toggle="tab" role="tab" aria-controls="nav-authentication" aria-selected="true">
        <img src="../../../assets/icons/facebook.svg" class="icon-size">
      </a>
    </div>
  </nav>
  <div [ngClass]="showSubscriptionForm ? 'text-container ' : 'info-container'">
    <form [formGroup]="subscriptionForm" [ngClass]="{ 'mt-10' : !showSubscriptionForm }">
      <div
        [ngClass]="showSubscriptionForm ? 'd-flex flex-column position-relative align-items-stretch' :  'd-flex flex-column align-items-center'">
        <div class="px-3 pt-3" *ngIf="showSubscriptionForm && !showUnsubscriptionForm">
          <div class="my-3 text-center" [ngClass]="{'mb-md-4 mt-md-4': shouldBeLarger}">
            {{updateText}}
          </div>
          <div class="d-flex h-46 input-button-container" [ngClass]="{'mb-md-1 mt-md-5': shouldBeLarger}">
            <div *ngIf=" selectedTab==='email'" class=" d-flex input-container">
              <input formControlName="email" type=text class="form-control h-auto" placeholder="{{ 'GENERIC.COMMON_PAGES.EMAIL_PLACEHOLDER' | translate }}">
            </div>

            <div *ngIf="selectedTab === 'mobile'" class="d-flex input-container">
              <input formControlName="mobile" type=text class="form-control h-auto" placeholder="{{ 'GENERIC.COMMON_PAGES.MOBILE_PLACEHOLDER' | translate }}">
            </div>
            <button [captureEvent]="'subscribe-email-sms'" (click)="submitForm(subscriptionForm)" *ngIf="!showUnsubscribeButton"
              [disabled]="checkButtonStatus()" type="submit" class="btn btn-primary flex-shrink-0 ml-2 subscribe-btn">
              <img src="/assets/icons/Bell.svg">
            </button>
          </div>
          <div style="line-height: 1;height:14px" [ngClass]="{'mb-md-3': shouldBeLarger}">
            <small *ngIf="selectedTab === 'email' && subscriptionForm.get('email')?.invalid" class="text-danger">
              {{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.EMAIL_ERROR' | translate }}
            </small>

            <small *ngIf="selectedTab === 'mobile' && subscriptionForm.get('mobile')?.invalid"
              class="text-danger">{{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.PHONE_ERROR' | translate }}</small>

            <small *ngIf="subscriptionFailed" class="text-danger">{{subscriptionFailedMessage}}</small>
          </div>
        </div>

        <div class="px-3 pt-3" *ngIf="showSubscriptionForm && showUnsubscriptionForm">
          <div class="my-3 text-center" [ngClass]="{'mb-md-4 mt-md-4': shouldBeLarger}">
            {{unsubscribeText}}
          </div>
          <div class="d-flex h-46 input-button-container" [ngClass]="{'mb-md-1 mt-md-5': shouldBeLarger}">
            <div *ngIf=" selectedTab==='email'" class="input-container d-flex">
              <input formControlName="email" type=text class="form-control h-auto" placeholder="{{ 'GENERIC.COMMON_PAGES.EMAIL_PLACEHOLDER' | translate }}">
            </div>

            <div *ngIf="selectedTab === 'mobile'" class="input-container d-flex">
              <input formControlName="mobile" type=text class="form-control h-auto" placeholder="{{ 'GENERIC.COMMON_PAGES.MOBILE_PLACEHOLDER' | translate }}">
            </div>
            <button (click)="onUnsubscribe(subscriptionForm)" *ngIf="!showUnsubscribeButton"
              [disabled]="checkButtonStatus()" type="submit" class="btn btn-primary flex-shrink-0 ml-2 subscribe-btn" [captureEvent]="'unsubscribe-email-sms'">
              <img src="/assets/icons/unsubscribebell.svg">
            </button>
          </div>
          <div style="line-height: 1;" [ngClass]="{'mb-md-3': shouldBeLarger}">
            <small *ngIf="selectedTab === 'email' && subscriptionForm.get('email')?.invalid" class="text-danger">
              {{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.EMAIL_ERROR' | translate }}
            </small>

            <small *ngIf="selectedTab === 'mobile' && subscriptionForm.get('mobile')?.invalid"
              class="text-danger">{{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.PHONE_ERROR' | translate }}</small>

            <small *ngIf="subscriptionFailed" class="text-danger">{{subscriptionFailedMessage}}</small>
          </div>
        </div>

        <div class="m-2 subscribe-success" *ngIf="!showSubscriptionForm">
          <img class="m-2" src="/assets/icons/Bell.svg" width="35" height="35" alt="">
        </div>
        <div class="p-1 text-center f-14" *ngIf="showSubscriptionSuccessMessage">
          <span>
            {{subscriptionSuccessMessage}}
          </span>
        </div>
        <div class="m-1 d-flex justify-content-between align-items-center sub-pad f-12"
          *ngIf="showUnsubscribeButton && !showUnsubscriptionForm && !showSubscriptionForm">
          <u class="px-3 unsubscribe" (click)="onUnsubscribeClicked()"
            [captureEvent]="'click-unsubscribe-email-sms-btn'">{{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.UNSUBSCRIBE' | translate }}</u>

          <u class="px-3 unsubscribe sub-border-left" (click)="onSubscribeForAnotherClick()"
            [captureEvent]="'click-subscribe-another-btn'">
            {{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.SUBSCRIBE' | translate }} {{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.ANOTHER' | translate }}
          </u>
        </div>
        <div class="m-1 d-flex justify-content-between align-items-center sub-pad f-12"
          *ngIf="showSubscribeButton && !showUnsubscriptionForm && !showSubscriptionForm">
          <u class="px-3 unsubscribe" (click)="onSubscribeForAnotherClick()"
            [captureEvent]="'click-subscribe-btn'">{{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.SUBSCRIBE' | translate }}</u>

          <u class="px-3 unsubscribe sub-border-left" (click)="onUnsubscribeClicked()"
            [captureEvent]="'click-unsubscribe-another-btn'">
            {{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.UNSUBSCRIBE' | translate }} {{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.ANOTHER' | translate }}
          </u>
        </div>
        <div class="d-flex justify-content-between px-3 mt-lg-4 mt-4 mt-md-0">
          <div class="f-12px"
                      *ngIf="showSubscriptionForm && !showUnsubscriptionForm">
                      {{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.SUBSCRIPTION_TERMS' | translate }} &nbsp;<a class="a-link text-nowrap" href="https://deliverysolutions.co/privacy"
                        target="_blank" [captureEvent]="'click-sms-email-terms'">{{  'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.SMS_EMAIL' | translate }}</a>
                    </div>
          <div *ngIf="showSubscriptionForm && !showUnsubscriptionForm" class="text-center f-12px ml-3">
            <span [captureEvent]="'click-unsubscribe-email-sms-btn'" (click)="onUnsubscribeClicked()" class="div-click a-link">
              {{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.UNSUBSCRIBE' | translate }}
            </span>
          </div>
          <div *ngIf="showSubscriptionForm && showUnsubscriptionForm" class="text-center f-12px w-100">
            <span class="div-click a-link" (click)="onSubscribeForAnotherClick()" [captureEvent]="'click-back-to-subscribe-btn'">
              {{ 'HOSTED_TRACKING.SUBSCRIBE_TO_UPDATES.BACK_TO_SUBSCRIBE' | translate }}
            </span>
          </div>
        </div>

      </div>
    </form>
  </div>
</div>

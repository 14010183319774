<div class="cookie-toast" id="cookie-toast" *ngIf="!dismissed" [@toastAnimation]>
  <div class="cookie-toast-content">
		<h3>{{ 'GENERIC.COMMON_PAGES.COOKIES_HEADING' | translate }}</h3>
    <br />
    <button type="button" class="cookie-toast-close-btn" (click)="dismissNotice()">&#10005;</button>
    <p class="cookie-toast-text">
			{{ 'GENERIC.COMMON_PAGES.COOKIES_TEXT' | translate }}
      <a href="https://deliverysolutions.co/privacy" class="privacy-link" target="_blank">
        <u>{{ 'GENERIC.COMMON_PAGES.PRIVACY_LINK_TEXT' | translate }}</u>
      </a> {{ 'GENERIC.COMMON_PAGES.COOKIES_TEXT_CONTINUE' | translate | lowercase }}
    </p>
  </div>
</div>

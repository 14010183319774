import { Component, Input, OnChanges, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { SharedService } from "../../services/shared.service";
import { OrderTypeController } from '@deliverysolutions/order-status-mapping';
import { Subscription } from 'rxjs';

export interface FeedBackForm {
  rating: null | string;
  comment: string;
}

@Component({
  selector: 'ds-feedback-box',
  templateUrl: './feedback-box.component.html',
  styleUrls: ['./feedback-box.component.scss']
})

export class FeedbackBoxComponent implements OnInit, OnChanges, OnDestroy {
  @Input() orderIdList!: string[];
  @Input() orderType!: string;
  @Input() source!: string;
  @Input() isFeedbackShared: boolean | undefined = undefined; // to restore state in case order has feedback shared before
  @Input() feedback: { rating: number, comment: string } | undefined = undefined;
  @Input() autoSave?: boolean | undefined = undefined;
  /**
   * default should be false
   * must be true when subscribe box is hidden and order details on the left has driver details
   */
  @Input() shouldBeLarger: boolean = false;
  @Input() isTerminalStatus!: boolean;
  public _orderType: string = '';
  public formObject: FeedBackForm = {
    rating: null,
    comment: ""
  };
  isFeedbackSentSubscription!: Subscription;

  constructor(private sharedService: SharedService) {
    this.isFeedbackShared = !!this.isFeedbackShared;
    this._orderType = this.orderType;
  }

  ngOnInit(): void {

    this.initState({ orderType: this.orderType, feedback: this.feedback, autoSave: this.autoSave });

    if (this.autoSave) {
      this.sharedService.sendFeedback(this.formObject, this.orderIdList[0], this.source).subscribe(value => {
        this.isFeedbackShared = false;
      });
    }
    this.sharedService.isBulkFeedbackSent$.subscribe(value => {
      this.isFeedbackShared = true;
      this.formObject = {
        rating: value.rating + "",
        comment: value.comment ?? ""
      }
    });
    this.isFeedbackSentSubscription = this.sharedService.isFeedbackSent$.subscribe(value => {
      this.isFeedbackShared = true;
      this.formObject = {
        rating: value.rating + "",
        comment: value.comment ?? ""
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initState({ orderType: changes.orderType?.currentValue, feedback: changes.feedback?.currentValue, autoSave: changes.autoSave?.currentValue });
  }

  ngOnDestroy(): void {
    this.isFeedbackSentSubscription.unsubscribe();
  }

  initState({ orderType, feedback }: { orderType?: string, feedback?: { rating: number, comment: string }, autoSave?: boolean }) {
    if (orderType) {
      let oType = this.orderType.toLowerCase();
      if (oType === OrderTypeController.DELIVERY) {
        this._orderType = 'delivery';
      } else if (oType === OrderTypeController.IN_STORE_PICKUP || oType === OrderTypeController.CURBSIDE) {
        this._orderType = 'pickup';
      } else {
        this._orderType = 'shipping';
      }
    } else {
      this._orderType = 'delivery';
    }

    if (feedback) {
      this.isFeedbackShared = true;
      this.formObject = {
        rating: feedback.rating + "",
        comment: feedback.comment
      }
    } else {
      this.isFeedbackShared = false;
      this.formObject = {
        rating: null,
        comment: ""
      }
    }
  }

  async onSubmit() {
    // rating field is mandatory
    if (!this.formObject.rating) {
      return;
    }

    this.isFeedbackShared = true;

    if (this.orderIdList.length > 1) {
      this.sharedService.sendBulkFeedback(this.formObject, this.orderIdList, this.source)
        .subscribe({ error: () => { this.isFeedbackShared = false } });
    } else {
      this.sharedService.sendFeedback(this.formObject, this.orderIdList[0], this.source)
        .subscribe({ error: () => { this.isFeedbackShared = false } });
    }
  }
}

export const DeliveryStatusReverseMapping: StringToString = {
  ARRIVED_AT_PICKUP: 'Arrived At Pickup',
  CANCELLED: 'Cancelled',
  CREATED: 'Created',
  DELIVERED: 'Delivered',
  DISPATCHED_TO_DSP: 'Dispatched To DSP',
  ESTIMATES_FAILED: 'Estimates Failed',
  ESTIMATES_RECEIVED: 'Estimates Received',
  FAILED: 'Failed',

  ORDER_ASSIGNED: 'Order Assigned',
  ORDER_CANCELLED: 'Order Canceled',
  ORDER_DELAYED: 'Order Delayed',
  ORDER_DELIVERED: 'Order Delivered',
  ORDER_DISPATCHED: 'Order Dispatched',
  STAGED: 'Staged',
  ORDER_FAILED: 'Order Failed',
  ORDER_PLACED: 'Order Placed',
  ORDER_REDELIVERY: 'Order Redelivery',
  ORDER_RETURNED: 'Order Returned',
  ORDER_UNASSIGNED: 'Order Unassigned',
  ORDER_UNDELIVERABLE: 'Order Undeliverable',

  OUT_FOR_DELIVERY: 'Out for Delivery',
  OUT_OF_DELIVERY: 'Out of Delivery',

  PENDING_AUTORETRY: 'Pending Auto Retry',
  PENDING_DISPATCH: 'Pending Dispatch',
  PENDING_RELEASE: 'Pending Release',

  PICKUP_COMPLETED: 'Pickup Completed',
  PICKUP_EXCEPTION: 'Pickup Exception',
  PICKUP_STARTED: 'Pickup Started',
  PICKUP_AT_LOCATION: 'Pickup At Location',

  REQUEST_FAILED: 'Request Failed',
  REQUEST_RECEIVED: 'Request Received',
  RETURNED: 'Returned',
  SHIPPING: 'Shipping',
  UNMAPPED_STATUS: 'Unmapped Status',

  /* New Status */

  IN_TRANSIT: 'In Transit',
  PARTIALLY_DELIVERED: 'Partially Delivered',
  PARTIALLY_UNDELIVERABLE: 'Partially Undeliverable',
  PARTIALLY_IN_TRANSIT: 'Partially In Transit',
  PARTIALLY_DELAYED: 'Partially Delayed',
  PARTIALLY_OUT_FOR_DELIVERY: 'Partially Out for Delivery',
  PARTIAL_EXCEPTION: 'Partial Exception',
  PARTIALLY_CANCELLED: 'Partially Cancelled',
  PARTIALLY_RETURNED: 'Partially Returned'
};

interface StringToString {
  [key: string]: string;
}

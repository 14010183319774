import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  authenticateFeedbackPage(): boolean {
    if (document.getElementById('feedback-page')) {
      return true;
    }
    return false;
  }
}
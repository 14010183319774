import { Component, Input, OnInit } from "@angular/core";
import { Attachment } from "src/app/ts-interfaces/order-interface";
@Component({
  selector: 'ds-status-history-thumbnail',
  templateUrl: './status-history-thumbnail.component.html',
  styleUrls: ['./status-history-thumbnail.component.scss']
})
export class StatusHistoryThumbnailComponent implements OnInit {
  filteredAttachments: string[] = [];
  @Input()
  set attachments(value: Attachment[] | undefined) {
    if (value) {
      this.filteredAttachments = value.filter(attachment => attachment.url).map(attachment => attachment.url).slice(0, 3).reverse();
    } else {
      this.filteredAttachments = [];
    }
  }
  ngOnInit(): void { }

}

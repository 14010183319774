import { OnChanges, Output, SimpleChanges, EventEmitter } from "@angular/core";
import { Component, Input, OnInit } from '@angular/core';
import { OrderInterface } from "../../ts-interfaces/order-interface";
import { NegativeFulfilmentStatus } from 'src/app/shared/constants/negative-fulfilment-status';
import { PickupStatusReverseMapping } from 'src/app/shared/constants/pickup-reverse-status';
import { OrderTypeController, StatusGroupController } from '@deliverysolutions/order-status-mapping';
import { TerminalStatus } from 'src/app/shared/constants/terminal-status';
import { getDSPLogoURL } from "src/app/shared/functions/get-dsp-logo-url";
import moment from "moment-timezone";
import { NullEmptyChecker } from "@deliverysolutions/utils";
import { TranslateService } from "@ngx-translate/core";
const red = '#EB5757';
@Component({
  selector: 'ds-order-status-card',
  templateUrl: './order-status-card.component.html',
  styleUrls: ['./order-status-card.component.scss']
})
export class OrderStatusCardComponent implements OnInit, OnChanges {
  @Input() order!: OrderInterface;
  @Output() detailsButtonClicked: EventEmitter<boolean> = new EventEmitter();
  public orderStatusInEnglish = "";
  public timeToShow: any;
  public timeToShowOnStatus: any;
  public orderTypeController = OrderTypeController;
  public beforeOrAfter = 'Arriving by';
  public getDSPLogoURL = getDSPLogoURL;
  public providerName: string | undefined = '';
  public statusGroup = new StatusGroupController();
  public modalEnabled = false;
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.updateViewVariables();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.order && changes.order.previousValue) {
      this.updateViewVariables();
    }
  }


  /**
   * For terminal statuses, display the label "At" and the time at which the order reached the terminal status
   * For pickup orders, display the label "Pickup by" and the estimated pickup time
   * For delivery orders, display the label "Arriving by" and the estimated delivery time
   * @returns
   */
  updateViewVariables() {
    if (this.order.type) {
      this.order.type = this.order.type.toLocaleLowerCase();
    }
    this.modalEnabled = this.order.status === 'ORDER_DELIVERED' && NullEmptyChecker.isNonEmptyArray(this.order.attachments) 

    if (this.order.provider || this.order.preferredProvider) {
      this.providerName = (this.order.carrier?.providerInfo.name === 'Self Delivery' ? this.order.provider :
       this.order.carrier?.providerInfo.name) || this.order.provider || this.order.preferredProvider;
    }
    this.orderStatusInEnglish = this.statusGroup.getDisplayName(this.order.status, this.order.type) || this.translate.instant('GENERIC.PLATFORM.NOT_APPLICABLE');
    if (TerminalStatus.includes(this.order.status)) {
      this.beforeOrAfter = this.translate.instant('GENERIC.PLATFORM.AT_TEXT');
      const statusIndex = this.order.statusHistory.reduce((acc, curr, index) => {
        return curr.status === this.order.status ? index : acc;
      }, -1);
      if (statusIndex === -1) {
        return;
       }
      const updatedAtEpoch = this.order.statusHistory[statusIndex].updatedAtEpoch;
      this.timeToShow = this.generateTimeToShow(updatedAtEpoch);
    } else {
      if (this.order.type === OrderTypeController.SHIPPING || this.order.type === OrderTypeController.DELIVERY || !this.order.type) {
        const updatedAtEpoch = this.order.estimatedDeliveryTime || this.order.dropoffTime?.endsAt || false;
        if (updatedAtEpoch) {
          this.beforeOrAfter = this.translate.instant('GENERIC.COMMON_PAGES.ARRIVING_BY');
          this.timeToShow = this.generateTimeToShow(updatedAtEpoch);
          this.timeToShowOnStatus = false;
        }
        else {
          this.timeToShow = false;
          if (this.order?.pickupTime?.startsAt) {
            const currentTimestamp = Date.now();
            const pickupMoment = moment(this.order?.pickupTime?.startsAt);
            this.timeToShowOnStatus = pickupMoment.format('MMM D, h:mm a');
            const currentMoment = moment(currentTimestamp);
            if (this.order.type === OrderTypeController.SHIPPING) {
              this.beforeOrAfter = pickupMoment.isBefore(currentMoment)
                ? this.translate.instant('GENERIC.COMMON_PAGES.SHIPPED_ON')
                : this.translate.instant('GENERIC.COMMON_PAGES.SHIPPING_ON');
            }
            else if (this.order.type === OrderTypeController.DELIVERY) {
              this.beforeOrAfter = pickupMoment.isBefore(currentMoment) 
                ? this.translate.instant('GENERIC.COMMON_PAGES.PICKED_UP_ON')
                : this.translate.instant('GENERIC.COMMON_PAGES.PICKUP_ON');
            }
          } else {
            this.beforeOrAfter = ''
            this.timeToShowOnStatus = false;
          }
        }
      } else {
        this.orderStatusInEnglish = PickupStatusReverseMapping[this.order.status] || this.statusGroup.getDisplayName(this.order.status, this.order.type.toLowerCase()) || "N/A";
        const updatedAtEpoch = this.order.estimatedPickupTime || this.order.pickupTime?.endsAt || false;
        if (updatedAtEpoch) {
          this.beforeOrAfter = this.translate.instant('GENERIC.COMMON_PAGES.PICKUP_BY');
          this.timeToShow = this.generateTimeToShow(updatedAtEpoch);
        }
        else {
          this.beforeOrAfter = ''
          this.timeToShow = false;
        }
      }
    }
  }

  generateTimeToShow(updatedAtEpoch: number | string) {
    const date = moment(updatedAtEpoch);
    const regexpMatch = date.tz(this.order.timeZone).format('dddd, MMMM D, YYYY, hh:mm A')
      .match(/^(\w+), (\w+) (\d+), \d+, (\d+:\d+ (?:A|P)M)$/);

    if (!regexpMatch) {
      return;
    }

    const timeToShow = {
      weekday: this.translate.instant(`GENERIC.COMMON_PAGES.${regexpMatch[1].toUpperCase()}`),
      day: regexpMatch[3],
      month: this.translate.instant(`GENERIC.COMMON_PAGES.${regexpMatch[2].toUpperCase()}`),
      time: regexpMatch[4]
    };
    return timeToShow;
  }

  getTerminalOrderStatus(status: string) {
    if (NegativeFulfilmentStatus.includes(status)) {
      return red;
    } else {
      return 'inherit'
    }
  }

  emitOpenModalEvent() {
    if (this.modalEnabled) {
    this.detailsButtonClicked.emit(true);
    }
  }

}

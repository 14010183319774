import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HostedTrackingConfig, AdConfig } from "../../ts-interfaces/hosted-tracking-config";
import { OrderInterface } from "../../ts-interfaces/order-interface";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'; // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

@Component({
  selector: 'ds-delivery-order-details',
  templateUrl: './delivery-order-details.component.html',
  styleUrls: ['./delivery-order-details.component.scss']
})
export class DeliveryOrderDetailsComponent implements OnInit {
  @Input() order!: OrderInterface;
  @Input() hostedTrackingConfig!: HostedTrackingConfig;
  @Input() isSystemStatus!: boolean;
  @Input() showSiblingOrders!: boolean;
  @Input() isNegativeStatus!: boolean;
  @Output() openDeliveryProofsModal: EventEmitter<boolean> = new EventEmitter();
  public primaryAds: AdConfig | undefined;

  constructor() { }

  ngOnInit(): void {
    this.primaryAds = this.hostedTrackingConfig.components.ads.find(ads => {
      return ads.active && ads.type === "primary";
    });
  }
}

import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { Userpilot } from "userpilot";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url.includes("feedback") && this.authService.authenticateFeedbackPage()) {
      Userpilot.reload();
      return true;
    } else if (!state.url.includes("feedback") && !this.authService.authenticateFeedbackPage()) {
      Userpilot.reload();
      return true;
    } else {
      this.router.navigate([""], { skipLocationChange: true });
      return false;
    }
  }
}

import { Injectable } from "@angular/core";
import { OnDestroy } from '@angular/core';
import { Subject, throwError, of } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BaseService implements OnDestroy {
  public serverRootPathV2: string;
  public serverUrl: string;
  public streamServiceUrl: string;
  public serviceDestroy$ = new Subject();

  constructor() {
    this.serverUrl = environment.serverUrl;
    this.streamServiceUrl = environment.streamServiceUrl;
    this.serverRootPathV2 = environment.serverUrl + 'api/v2/';
  }

  public ngOnDestroy() {
    this.serviceDestroy$.next(true);
    this.serviceDestroy$.complete();
  }

  public _errorHandler(error: any) {
    return throwError(error || 'Server Error');
  }
}

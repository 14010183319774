import { Component, OnInit, Input } from '@angular/core';
import { Recommendations } from 'src/app/ts-interfaces/hosted-tracking-config';
import { SwiperOptions } from 'swiper';

const imagesInSingleCarousel = 4;
@Component({
  selector: 'ds-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss']
})
export class RecommendationsComponent implements OnInit {
  @Input() recommendations!: Recommendations[] | [];

  constructor() { }

  ngOnInit(): void { }

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    autoHeight: true,
    allowTouchMove: true,
    slidesPerGroup: 4,
    breakpoints: {
      1024: {
        slidesPerView: 4,
        slidesPerGroup: 4
      },
      1010: {
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      990: {
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2

      },
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: true,
    spaceBetween: 30,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
  };
}

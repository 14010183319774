import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { ItemList } from "../../ts-interfaces/order-interface";
import { HostedTrackingConfig } from 'src/app/ts-interfaces/hosted-tracking-config';


@Component({
  selector: 'ds-package-item-list',
  templateUrl: './package-item-list.component.html',
  styleUrls: ['./package-item-list.component.scss']
})
export class PackageItemListComponent implements OnInit {
  @Input() itemList!: ItemList[];
  @Input() hostedTrackingConfig!: HostedTrackingConfig;
  @Input() returnsUrl!: string | null;

  public isActiveItemCount = false;
  public isActiveItemDetails = false;
  public isActiveReturnsInitiation = false;

  constructor() {}

  ngOnInit(): void {
    this.initializeComponentVisibility();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemList) {
      this.itemList = changes.itemList.currentValue;
    }
    if(changes.returnsUrl){
      this.returnsUrl = changes.returnsUrl.currentValue;
    }
    if(changes.hostedTrackingConfig){
      this.hostedTrackingConfig = changes.hostedTrackingConfig.currentValue;
    }
    this.initializeComponentVisibility();
  }

  initializeComponentVisibility(): void {
    this.isActiveItemCount = this.hostedTrackingConfig.componentVisibility?.packageDetails?.isActiveItemCount;
    this.isActiveItemDetails = this.hostedTrackingConfig.componentVisibility?.packageDetails?.isActiveItemDetails;
    this.isActiveReturnsInitiation = !!(
      this.hostedTrackingConfig.componentVisibility?.packageDetails?.isActiveReturnsInitiation &&
      typeof this.returnsUrl === "string"
    );
  }
  openHostedReturnsUrl() {
    if (this.returnsUrl) window.open(this.returnsUrl, "_blank");
  }
}

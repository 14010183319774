import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
} from '@angular/router';
import { HostedTrackingConfigService } from '../hosted-tracking-config.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HostedFeedbackConfingResolver implements Resolve<any> {

  constructor(
    private hostedTrackingConfigService: HostedTrackingConfigService
  ) { }

  resolve(route: ActivatedRouteSnapshot) {

    let brandExternalId = route.params && route.params["brandExternalId"] ? route.params["brandExternalId"] : undefined;

    // when user lands on custom domain fetch config based on custom domain.
    if (environment.env !== "local" && !window.location.host.includes('localhost') && !window.location.host.endsWith('deliverysolutions.co')) {
      return this.hostedTrackingConfigService.fetchHostedFeedbackConfig(brandExternalId, window.location.host);
    }

    return this.hostedTrackingConfigService.fetchHostedFeedbackConfig(brandExternalId);
  }
}

import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Header, BusinessLogo } from "../../ts-interfaces/hosted-tracking-config";

@Component({
  selector: 'ds-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit, OnChanges {
  @Input() header!: Header;
  @Input() businessLogos!: BusinessLogo[];
  @Input() isBackgroundWhite!: boolean;
  public window = window;
  headerLogo: BusinessLogo | undefined;
  @ViewChild('sidenav', { static: false }) sideNav!: ElementRef

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.headerLogo = this.businessLogos && this.businessLogos.find(logo => {
      return logo.size === "large";
    });
  }

  openNav() {
    if (this.sideNav.nativeElement) {
      this.renderer.removeClass(this.sideNav.nativeElement, 'w-0');
      this.renderer.addClass(this.sideNav.nativeElement, 'w-100');
      document.body.classList.add('overflow-hidden');
    }
  }

  closeNav() {
    if (this.sideNav.nativeElement) {
      this.renderer.removeClass(this.sideNav.nativeElement, 'w-100');
      this.renderer.addClass(this.sideNav.nativeElement, 'w-0');
      document.body.classList.remove('overflow-hidden');
    }
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { HostedTrackingConfig, AdConfig } from "../../ts-interfaces/hosted-tracking-config";
import { OrderInterface } from "../../ts-interfaces/order-interface";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

@Component({
  selector: 'ds-shipment-order-details',
  templateUrl: './shipment-order-details.component.html',
  styleUrls: ['./shipment-order-details.component.scss']
})
export class ShipmentOrderDetailsComponent implements OnInit {
  @Input() order!: OrderInterface;
  @Input() parentOrder!: OrderInterface;
  @Input() hostedTrackingConfig!: HostedTrackingConfig;
  @Input() isSystemStatus!: boolean;
  @Input() showSiblingOrders!: boolean;
  @Output() openDeliveryProofsModal: EventEmitter<boolean> = new EventEmitter();

  primaryAds: AdConfig | undefined;
  childOrderIdList: string[] = [];

  childOrderList: OrderInterface[] = [];

  constructor() { }

  ngOnInit(): void {
    this.childOrderList = this.parentOrder.htChildOrdersDto || [];
    this.childOrderIdList = this.childOrderList.map(order => order._id);

    this.primaryAds = this.hostedTrackingConfig.components.ads.find(ads => {
      return ads.active && ads.type === "primary";
    });
  }
}

<!-- Header section start -->
<div class="nav-cnt">
  <div class="container">
    <div class="row">
      <div class="col">
        <ds-site-header [header]="hostedTrackingConfig?.components?.header!"
          [businessLogos]="hostedTrackingConfig?.branding?.businessLogos!">
        </ds-site-header>
      </div>
    </div>
  </div>
</div>
<!-- Header section end -->

<!-- Broadcast section start -->
<div class="container">
  <div class="row">
    <div class="col">
      <ds-broadcast-message *ngIf="hostedTrackingConfig?.components?.broadcast?.active"
        [hostedTrackingConfig]="hostedTrackingConfig!"></ds-broadcast-message>
    </div>
  </div>
</div>
<!-- Broadcast section end -->

<!-- order ddetails -->
<div class="container" *ngIf="order && fetchedOrder && !isSystemStatus">
  <ds-order-details [order]="order" [hostedTrackingConfig]="hostedTrackingConfig!" [isTerminalStatus]="isTerminalStatus"
      [isSystemStatus]="isSystemStatus" [parentOrder]="fetchedOrder" [isSubscribeBoxVisible]="isSubscribeBoxVisible"
      [isFeedbackBoxVisible]="isFeedbackBoxVisible" [subscribeToUpdateOptions]="subscribeToUpdateVisibility">
    </ds-order-details>
</div>

<div class="container" *ngIf="order && isSystemStatus">
  <div class="row">
    <h1 class="col orderid my-4">
      {{ 'GENERIC.PLATFORM.ORDER_TEXT' | translate }} #{{order.orderExternalId}}
    </h1>
  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <ds-info-unavailable></ds-info-unavailable>
    </div>
    <div class="col-12 col-md-6 mt-3 mt-md-0">
      <ds-side-ad-banner [adconfig]="primaryAds" *ngIf="primaryAds"></ds-side-ad-banner>
    </div>
  </div>

</div>

<!-- recommendations start -->
<div class="container mt-3" *ngIf="recommendations">
  <div class="row">
    <div class="col">
      <ds-recommendations [recommendations]="recommendations"></ds-recommendations>
    </div>
  </div>
</div>
<!-- recommendations end-->

<!-- Second ad banner start -->
<div class="container mt-3" *ngIf="secondaryAdsOne || secondaryAdsTwo">
  <div class="row">
    <div class="col">
      <ds-wide-ads-banner [secondaryAdsOne]="secondaryAdsOne" [secondaryAdsTwo]="secondaryAdsTwo"
        [component]="'hosted-tracking'"></ds-wide-ads-banner>
    </div>
  </div>
</div>
<!-- Second ad banner end-->

<!-- footer start-->
<div class="container mt-md-5 mt-3">
  <div class="row">
    <div class="col">
      <ds-site-footer [order]="order"></ds-site-footer>
    </div>
  </div>
</div>
<!-- footer end-->


<!-- Need Help start -->
<a class="need-help-section f-16 px-3 py-2" [href]="hostedTrackingConfig?.components?.needHelp?.helpUrl"
  *ngIf="hostedTrackingConfig?.components?.needHelp?.helpUrl" target="_blank">
  <span class="question-mark">?</span>&nbsp;<span>
    {{ hostedTrackingConfig?.components?.needHelp?.displayText || ('HOSTED_FEEDBACK.FEEDBACK_COMPONENT.NEED_HELP' | translate) }}
  </span>
</a>
<!-- Need Help end -->

<div class="spinner d-flex align-items-center justify-content-center" *ngIf="isPageLoading">
  <div class="lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { OrderTypeController } from '@deliverysolutions/order-status-mapping';
import { DeliveryStatusReverseMapping } from 'src/app/shared/constants/delivery-reverse-status';
import { SystemStatus } from 'src/app/shared/constants/system-status';
import { generateTimeFormatForStatusHistory } from 'src/app/shared/utils/date-utils';
import { HostedTrackingConfig } from 'src/app/ts-interfaces/hosted-tracking-config';
import { Attachment, StatusHistory } from 'src/app/ts-interfaces/order-interface';

@Component({
  selector: 'ds-status-history',
  templateUrl: './status-history.component.html',
  styleUrls: ['./status-history.component.scss']
})
export class StatusHistoryComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() orderType!: string;
  @Input() _statusHistory!: StatusHistory[];
  @Input() timeZone!: string;
  @Input() desktopMode = false;
  @Input() hostedTrackingConfig: HostedTrackingConfig | undefined;
  public statusHistory: StatusHistory[] = [];
  private DSRM = DeliveryStatusReverseMapping;
  orderTypeController = OrderTypeController;
  latestTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date());
  showArrows = false;
  isOpen = false;
  orderDelivered = false;
  public mobileViewStatusList: StatusHistory[] = [];
  componentWidth = '100%';
  @ViewChild('statusHistoryCnt') statusHistoryCnt: ElementRef<HTMLElement> | undefined;
  @ViewChild('statusHistoryDiv') statusHistoryDiv: ElementRef<HTMLElement> | undefined;
  showAllInMobileMode = false;
  images: string[] = [];
  @Input() attachments: Array<Attachment> | undefined;
  @Output() detailsButtonClicked: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.showArrows = this.desktopMode;
    this.isOpen = this.desktopMode;
    this.componentWidth = this.desktopMode ? '300px' : '100%';
    this.filterAndReverseStatusHistory(this._statusHistory);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?._statusHistory?.previousValue) {
      // update status history/activity list
      changes._statusHistory.currentValue && this.filterAndReverseStatusHistory(changes._statusHistory.currentValue);

      // angular needs to update, so we wait
      setTimeout(() => {
        this.updateStyling();
      }, 0)
    }
  }

  ngAfterViewInit(): void {
    this.updateStyling();
  }

  updateStyling() {
    if (this.desktopMode && this.statusHistoryCnt && this.statusHistoryDiv) {
      const statusHistoryCntElem = this.statusHistoryCnt.nativeElement;
      const statusHistoryDivElem = this.statusHistoryDiv.nativeElement;
      statusHistoryCntElem.style.backgroundPosition = `left 0px top ${statusHistoryDivElem.offsetHeight}px`
    }
  }

  private filterAndReverseStatusHistory(statusHistoryList: StatusHistory[]) {
    this.orderDelivered = statusHistoryList[statusHistoryList.length - 1]?.status === 'ORDER_DELIVERED';
    this.statusHistory = Array.from(statusHistoryList
      .filter(history => !SystemStatus.includes(history.status))).reverse();
    this.updateStatusNote();
    generateTimeFormatForStatusHistory(this.statusHistory, this.timeZone);
    this.mobileViewStatusList = Array.from(this.statusHistory).slice(0, 1);
  }

  private updateStatusNote() {
    for (let status of this.statusHistory) {
      if (!status.note) {
        continue;
      }

      if (this.orderType === OrderTypeController.DELIVERY) {
        if (this.hostedTrackingConfig?.componentVisibility.showDeliveryNotes) {
          status.note = !this.DSRM[status.note] && !status.note.includes('Alternate DSP') ? status.note
            : "";
        } else {
          status.note = "";
        }
      } else {
        status.note = !this.DSRM[status.note] && !status.note.includes('Alternate DSP') ? status.note
          : "";
      }
    }
  }
  public toggleMobileStatusList() {
    if (!this.showAllInMobileMode) {
      this.mobileViewStatusList = Array.from(this.statusHistory);
      this.showAllInMobileMode = true;
      return;
    }

    this.showAllInMobileMode = false;
    this.mobileViewStatusList = Array.from(this.statusHistory).slice(0, 1);
  }
  emitOpenModalEvent() {
    this.detailsButtonClicked.emit(true);
  }
}
